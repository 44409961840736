import { Injectable, EventEmitter, Output } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { Router } from '@angular/router';

import { DEFINITION_MENU_ITEMS } from '@app/core/shell/menu/definition-menu-item';
import { MASTER_MENU_ITEMS } from '@app/core/shell/menu/master-menu-item';
import { INBOUND_MENU_ITEMS } from '@app/core/shell/menu/inbound-menu-item';
import { PROJECT_CONFIG_MENU_ITEMS } from '@app/core/shell/menu/project-config-menu-item';
import { IdentityService } from '@app/core/authentication/identity.service';
import { MASTER_ADMIN_MENU_ITEMS } from '@app/core/shell/menu/admin/master-admin-menu-item';
import { INVENTORY_MENU_ITEMS } from '@app/core/shell/menu/inventory-menu-item';
import { Project_ADMIN_MENU_ITEMS } from './admin/project-admin-menu-item';
import { USER_MENU_ITEMS } from './admin/user-menu-item';
import { OUTBOUND_MENU_ITEMS } from './outbound-menu-item';
import { PROJECT_CONFIG_ADMIN_MENU_ITEMS } from './admin/project-config-admin-menu-item';
import { TRACKING_MENU_ITEMS } from './tracking-menu';
import { ADMIN_DEFINITION_MENU_ITEMS } from './admin/admin-definition-menu-item';
import { RoleService } from '@app/modules/admin/user/role/role.service';
import { ApiResponseDataModel } from '@app/core/api-respone-data.model';
import { RoleModel } from '@app/modules/admin/user/role/role.model';
import { ProjectService } from '@app/shared/services/project.service';
import { StorageService } from '@app/core/storage.service';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { EncryptionService } from '@app/core/authentication/encryption.service';
import { ModulesMenu } from '@app/modules/modules-menu.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  @Output() valueChange = new EventEmitter<any>();
  public userData: any;
  ProjectId: any;
  dataRole: any[] = [];
  RoleId: string[] = [];
  arrRoleId: string[] = [];
  menuData: NbMenuItem[] = [];
  roleData: NbMenuItem[] = [];
  GetMenuData: NbMenuItem[] = [];
  MenuShowdata: NbMenuItem[] = [];
  data: any[];
  eventEmitter: EventEmitter<any> = new EventEmitter<any>();
  // ประกาศตัวแปร เพื่อรอเรียก function แบบ await
  // idMenu: number;

  public MENU_ITEMS: NbMenuItem[];
  public MODULES_MENU_ITEMS: ModulesMenu[];
  private canAccessAdminMenu = false;
  constructor(
    private router: Router,
    private identityService: IdentityService,
    private roleService: RoleService,
    private projectservice: ProjectService,
    private storage: StorageService,
    private encryption: EncryptionService
  ) {
    // this.projectservice.getMenuShow().subscribe((data: any) => {
    //   this.data = data.Result;
    // }, () => { }, () => { this.getMenuItem(); });
  }

  getMenuShowdata() {
    const Domain = 'WMS-Main';
    let res;
    this.projectservice.getMenuShow(Domain).subscribe((data: any) => {
      res = data;
      localStorage.setItem('Menu', this.encryption.forceEncrypt(data.Result));
    }, () => {
      localStorage.setItem('Menu', '');
      this.eventEmitter.error({ err: 'err' });
    }, () => {
      this.data = this.encryption.forceDecrypt(localStorage.getItem('Menu'));
      this.eventEmitter.emit();
      // console.log(this.eventEmitter);
      // console.log('in emit');
      // this.eventEmitter.complete(); // ถ้า compleate แล้วให้ หน้า module.component.ts เรียกแล้ว subscribe
    });
  }

  callMenudataIsnull() {
    this.data = this.encryption.forceDecrypt(localStorage.getItem('Menu'));
  }

  findMainMenu(parent: number): any[] {
    let tmp: any[];
    tmp = this.data.filter(a => a.parent == parent);
    const arr = [];
    tmp.forEach(element => {
      const menu = {
        title: element.name,
        link: element.link,
        children: [],
        icon: element.image,
      };
      menu.children = this.findMainMenu(element.id);
      if (element.flag == 1 || menu.children.length > 0 && element.parent === 0) {
        menu.children = null;
        arr.push(menu);
      }
    });
    return arr;
  }

  findMenu(parent: number): any[] {
    let tmp: any[];
    tmp = this.data.filter(a => a.parent == parent);
    const arr = [];
    tmp.forEach(element => {
      const menu = {
        title: element.name,
        link: element.link,
        data: element.id,
        children: [],
        icon: element.picture,
      };
      menu.children = this.findMenu(menu.data);
      if (menu.children.length === 0 && element.flag == 1) {
        delete menu.children;
        arr.push(menu);
      } else if (element.flag == 1 || menu.children.length > 0) {
        arr.push(menu);
      }
    });
    return arr;
  }

  getMenuItem(): NbMenuItem[] {
    this.callMenudataIsnull();
    let idMenu: number;
    this.canAccessAdminMenu = this.identityService.getProjectName() === 'Admin Project' && this.identityService.isAdmin();
    const urlSplit = (this.router.url.indexOf('?') > -1) ? this.router.url.split('?')[0].split('/') : this.router.url.split('/');
    if (!this.canAccessAdminMenu) {
      const moduleUrl = urlSplit[2];
      this.MENU_ITEMS = [];
      switch (moduleUrl) {
        case 'inbound':
          idMenu = 4;
          this.MENU_ITEMS = this.findMenu(idMenu);
          break;
        case 'master':
          idMenu = 3;
          this.MENU_ITEMS = this.findMenu(idMenu);
          break;
        case 'definition':
          idMenu = 2;
          this.MENU_ITEMS = this.findMenu(idMenu);
          break;
        case 'inventory':
          idMenu = 5;
          this.MENU_ITEMS = this.findMenu(idMenu);
          break;
        case 'projectconfig':
          idMenu = 1;
          this.MENU_ITEMS = this.findMenu(idMenu);
          break;
        case 'outbound':
          idMenu = 6;
          console.log(idMenu);
          this.MENU_ITEMS = this.findMenu(idMenu);
          console.log(this.MENU_ITEMS);
          break;
        case 'tracking':
          this.MENU_ITEMS = TRACKING_MENU_ITEMS;
          break;
      }
    } else {
      const moduleUrl = urlSplit[2] + urlSplit[3];
      switch (moduleUrl) {
        case 'adminmaster':
          this.MENU_ITEMS = MASTER_ADMIN_MENU_ITEMS;
          break;
        case 'adminuser':
          this.MENU_ITEMS = USER_MENU_ITEMS;
          break;
        case 'definitioncustomer':
        case 'definitionundefined':
          this.MENU_ITEMS = ADMIN_DEFINITION_MENU_ITEMS;
          break;
        case 'adminprojectconfig':
          this.MENU_ITEMS = PROJECT_CONFIG_ADMIN_MENU_ITEMS;
      }
    }

    const permissionList = this.identityService.getMenuPermission();
    if (this.identityService.isAdmin) {
      return this.MENU_ITEMS;
    }
    if (permissionList && permissionList.length > 0) {
      this.MENU_ITEMS = this.findPermissionReccursive(this.MENU_ITEMS, permissionList);
    }
    return this.MENU_ITEMS;
  }

  // ----------------------------------------------------------------
  // getMenuItem(): NbMenuItem[] {
  //   this.canAccessAdminMenu = this.identityService.getProjectName() === 'Admin Project' && this.identityService.isAdmin();
  //   const urlSplit = (this.router.url.indexOf('?') > -1) ? this.router.url.split('?')[0].split('/') : this.router.url.split('/');
  //   this.MENU_ITEMS = [];
  //   if (!this.canAccessAdminMenu) {
  //     const moduleUrl = urlSplit[2];
  //     switch (moduleUrl) {
  //       case 'inbound':
  //         this.MENU_ITEMS = INBOUND_MENU_ITEMS;
  //         break;
  //       case 'master':
  //         this.MENU_ITEMS = MASTER_MENU_ITEMS;
  //         break;
  //       case 'definition':
  //         this.MENU_ITEMS = DEFINITION_MENU_ITEMS;
  //         break;
  //       case 'inventory':
  //         this.MENU_ITEMS = INVENTORY_MENU_ITEMS;
  //         break;
  //       case 'projectconfig':
  //         this.MENU_ITEMS = PROJECT_CONFIG_MENU_ITEMS;
  //         break;
  //       case 'outbound':
  //         this.MENU_ITEMS = OUTBOUND_MENU_ITEMS;
  //         break;
  //       case 'tracking':
  //         this.MENU_ITEMS = TRACKING_MENU_ITEMS;
  //         break;
  //     }
  //   } else {
  //     const moduleUrl = urlSplit[2] + urlSplit[3];
  //     switch (moduleUrl) {
  //       case 'adminmaster':
  //         this.MENU_ITEMS = MASTER_ADMIN_MENU_ITEMS;
  //         break;
  //       case 'adminuser':
  //         this.MENU_ITEMS = USER_MENU_ITEMS;
  //         break;
  //       case 'definitioncustomer':
  //       case 'definitionundefined':
  //         this.MENU_ITEMS = ADMIN_DEFINITION_MENU_ITEMS;
  //         break;
  //       case 'adminprojectconfig':
  //         this.MENU_ITEMS = PROJECT_CONFIG_ADMIN_MENU_ITEMS;
  //     }
  //   }
  //   let tempMenu = this.MENU_ITEMS; // JSON.parse(JSON.stringify(this.MENU_ITEMS));


  //   // const items: any[] = [];
  //   // if (items) {
  //   // this.MENU_ITEMS.forEach(f => {
  //   //   if (f.data || f.children ? f.children.some(s => s.data) : false) {
  //   //     let isPush = true;
  //   //     if (f.data) {
  //   //       isPush = this.identityService.isAllowPermission(f.data);
  //   //     } else {
  //   //       // const tempItem: any[] = [];
  //   //       // f.children.map((m) => {
  //   //       //   if (m.data) {
  //   //       //     if (this.identityService.isAllowPermission(m.data)) {
  //   //       //       return m;
  //   //       //     }
  //   //       //   } else {
  //   //       //     return m;
  //   //       //   }
  //   //       // });
  //   //     }
  //   //     if (isPush) {
  //   //       items.push(f);
  //   //     }
  //   //   } else {
  //   //     items.push(f);
  //   //   }
  //   // });
  //   // }
  //   const permissionList = this.identityService.getMenuPermission();
  //   if (this.identityService.isAdmin) {
  //     return tempMenu;
  //   }
  //   if (permissionList && permissionList.length > 0) {
  //     tempMenu = this.findPermissionReccursive(tempMenu, permissionList);
  //   }
  //   return tempMenu;
  // }

  findPermissionReccursive(items: any[], permissionList: any[]) {
    const temp: any[] = [];
    items.forEach(f => {
      if (f.children) {
        f.children = this.findPermissionReccursive(f.children, permissionList);
        f.children = f.children.length > 0 ? f.children : undefined;
      }
      if (f.data) {
        if (permissionList.some(a => a == f.data) || f.children) { temp.push(f); }
      } else {
        temp.push(f);
      }
    });
    return temp;

  }
}

import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel, HttpTransportType } from '@aspnet/signalr';
import { Subject } from 'rxjs';
import { environment } from '@env/environment';
import { TokenHelperService } from '@app/core/authentication/token-helper.service';

const WAIT_UNTIL_ASPNETCORE_IS_READY_DELAY_IN_MS = 2000;

@Injectable()
export class SignalrService {
  private signalrHubUrl = environment.service.core.signalR;
  private hubConnection: HubConnection;

  notifications = new Subject<any>();
  isConnect = 0;
  public userKey = '';

  constructor(private tokenHelperService: TokenHelperService) {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }

  public getNotifications() {
    try {
      this.hubConnection.send('NotificationsAsync');
    } catch (error) {
      this.isConnect = 0;
    }
  }

  public retry() {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }

  private createConnection() {
    const token = this.tokenHelperService.GetToken();
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.signalrHubUrl + '/signalrhub', {
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => token
      })
      .build();
    this.hubConnection.serverTimeoutInMilliseconds = 120000;
  }
  private startConnection() {
    setTimeout(() => {
      this.hubConnection.start().then(() => {
        console.log('SignalR Hub connection started');
        this.getNotifications();
        this.isConnect = this.hubConnection.state;
      }).catch(() => {
        console.log('Error while establishing connection');
        this.isConnect = this.hubConnection.state;
      });
    }, WAIT_UNTIL_ASPNETCORE_IS_READY_DELAY_IN_MS);
  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('NotificationsAsync', (msg) => {
      console.log(msg);
      this.notifications.next(msg);
    });
  }

}

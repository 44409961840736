import { ModulesMenu } from '@app/modules/modules-menu.model';

export const MODULES_ADMIN_MENU_ITEMS: ModulesMenu[] = [
  {
    title: 'Definition Management',
    icon: 'Definition_management.png',
    link: '/modules/definition'
  },
  {
    title: 'Master Management',
    icon: 'Master_Management.png',
    link: '/modules/admin/master'
  },
  {
    title: 'User',
    icon: 'User_Management.png',
    link: '/modules/admin/user'
  },
  {
    title: 'Project Configuration',
    icon: 'Project_management.png',
    link: '/modules/admin/selectproject'
  },
  {
    title: 'Logging',
    icon: 'Logging.png',
    link: '/modules/admin/logging'
  }

];

import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { PopupModalComponent } from '@app/shared/components/popup/popup-modal/popup-modal.component';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import { UserSettingService } from '@app/shared/services/user-setting.service';
import { MainConfig } from '@app/shared/models/project-config/main-config';
import { ProjectConfigSet } from '@app/core/helper/set-value/project-config-set';
import { OrderService } from '@app/modules/outbound/order/order.service';
import { ProjectConfigService } from '@app/shared/services/project-config.service';

import { FormGroup, FormControl, FormArray } from "@angular/forms";
import {
  DynamicFormArrayModel,
  DynamicFormControlModel,
  DynamicFormLayout,
  DynamicFormService,
  DynamicInputModel,
  DynamicDatePickerModel
} from "@ng-dynamic-forms/core";
import { BOOTSTRAP_SAMPLE_FORM_MODEL } from "./bootstrap-sample-form.model";
import { BOOTSTRAP_SAMPLE_FORM_LAYOUT } from "./bootstrap-sample-form.layout";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {


  public formModel: DynamicFormControlModel[] = BOOTSTRAP_SAMPLE_FORM_MODEL;
  public formGroup: FormGroup;
  formLayout: DynamicFormLayout = BOOTSTRAP_SAMPLE_FORM_LAYOUT;

  exampleControl: FormControl;
  exampleModel: DynamicInputModel;

  arrayControl: FormArray;
  arrayModel: DynamicFormArrayModel;

  @Input() fieldJSON: string;
  @Input() searchJSON: string;
  @Input() fullJSON: any[];
  @Input() usePage: string;
  @Input() projectConfig: any;
  @Output() deletePageRadio: string;
  @Output() outputModel: any[] = [];
  @Output() searchQuery: any[] = [];
  @Output() fieldList: any[] = [];
  @Output() sendInfo: EventEmitter<{ status: string }> = new EventEmitter<{ status: string }>();


  showConfig: boolean;
  canConfig = true;
  columns: any[] = [];
  fieldColumns: any[] = [];

  selectedColumns: any[] = [];
  selectedFieldColumns: any[] = [];

  searchRowsDisplay: any[] = [];
  searchRows: any[] = [];
  fieldRows: any[] = [];

  startDate: Date;
  endDate: Date;
  isConfigField: boolean;
  isCheckSerial: boolean;
  isOrderCanEdit: boolean;
  maxSerialNumber: 1;

  radioValue: string;
  jsonFormModel: string;
  inputModel: DynamicDatePickerModel;

  public codeList = [
    {
      id: 1,
      code: 'ItemCode',
      name: 'Item Code'
    },
    {
      id: 2,
      code: 'ScanCode',
      name: 'Scan Code'
    },
    {
      id: 3,
      code: 'JAN',
      name: 'JAN'
    }
  ];

  public orderdateList = [
    {
      id: 1,
      code: 'OrderDate',
      name: 'Order Date'
    },
    {
      id: 2,
      code: 'OrderDateFromSystem',
      name: 'Order Date (System)'
    }
  ];

  codeIsChecked = false;

  constructor(private orderService: OrderService,
    private popup: PopupModalComponent,
    private settingService: UserSettingService,
    private projectConfigService: ProjectConfigService,
    private formService: DynamicFormService
  ) {
    this.startDate = new Date();
    this.endDate = new Date();

    if (!this.projectConfig) {
      this.projectConfig = this.projectConfigService.getAllConfigs();
      ProjectConfigSet.setProjectConfigItemToColumn(this.projectConfig, this.fieldList);
      this.projectConfig = this.projectConfig;
      this.usePage = 'orderdetailsearch';
    }
  }

  ngOnInit() {
    const formModelJson = `[
      {
          "type": "INPUT",
          "id": "sampleInput",
          "label": "Sample Input",
          "maxLength": 42,
          "placeholder": "Sample input"
      },
      {
          "type": "RADIO_GROUP",
          "id": "sampleRadioGroup",
          "label": "Sample Radio Group",
          "options": [
              {
                  "label": "Option 1",
                  "value": "option-1"
              },
              {
                  "label": "Option 2",
                  "value": "option-2"
              },
              {
                  "label": "Option 3",
                  "value": "option-3"
              }
          ],
          "value": "option-3"    
      },
      {
          "type": "CHECKBOX",
          "id": "sampleCheckbox",
          "label": "I do agree"
      }
  ]`;

    this.jsonFormModel = JSON.stringify(this.formModel);

    this.formModel = this.formService.fromJSON(this.jsonFormModel);
    this.formGroup = this.formService.createFormGroup(this.formModel);





    if (this.fieldJSON != undefined) {
      this.isConfigField = true;
    }
    if (this.projectConfig != undefined) {
      if (this.projectConfig.item) {
        if (!this.projectConfig.item.usedScanCode) {
          const indexScan = this.codeList.findIndex(f => f.code == 'ScanCode');
          if (indexScan > 0) { this.codeList.splice(indexScan, 1); }
        }
        if (!this.projectConfig.item.usedJanCode) {
          const indexJan = this.codeList.findIndex(f => f.code == 'JAN');
          if (indexJan > 0) { this.codeList.splice(indexJan, 1); }
        }
        this.maxSerialNumber = this.projectConfig.item.maxSerialNumber;
      }
      if (this.projectConfig.order) {
        this.isOrderCanEdit = !this.projectConfig.order.importOption ?
          false
          : this.projectConfig.order.importOption.canEditOrder;
      }
    }

    this.orderService.getDefColumn('itemsearch')
      .subscribe((res: any) => {
        this.fullJSON = res.Result;
        this.loadFilter();
      });


    if (this.deletePageRadio === undefined) {
      this.deletePageRadio = '1';
    }

  }

  add() {
    this.formService.addFormArrayGroup(this.arrayControl, this.arrayModel);
  }

  insert(context: DynamicFormArrayModel, index: number) {
    this.formService.insertFormArrayGroup(index, this.arrayControl, context);
    console.log(this.formModel);
  }

  remove(context: DynamicFormArrayModel, index: number) {
    this.formService.removeFormArrayGroup(index, this.arrayControl, context);
  }

  move(context: DynamicFormArrayModel, index: number, step: number) {
    this.formService.moveFormArrayGroup(index, step, this.arrayControl, context);
  }

  clear() {
    this.formService.clearFormArray(this.arrayControl, this.arrayModel);
  }

  test() {
    //this.exampleModel.disabledUpdates.next(!this.exampleModel.disabled);
    //this.exampleModel.valueUpdates.next("Hello Hello");
    //console.log(JSON.stringify(this.exampleModel));
    //this.arrayModel.get(1).group[0].valueUpdates.next("This is just a test");
    //this.formService.moveFormArrayGroup(2, -1, this.arrayControl, this.arrayModel);
    /*
    this.formService.addFormGroupControl(
        this.formGroup,
        this.formModel,
        new DynamicFormGroupModel({
            id: "bsFormGroup3",
            group: [new DynamicInputModel({id: "newInput"})]
        })
    );
    this.formService.addFormGroupControl(
        this.formGroup.get("bsFormGroup3") as FormGroup,
        this.formModel[2] as DynamicFormGroupModel,
        new DynamicInputModel({id: "newInput"})
    );
    */
    //this.exampleModel.add({label: "Option 5", value: "option-5"});
  }

  onBlur($event) {
    console.log(`BLUR event on ${$event.model.id}: `, $event);
  }

  onChange($event) {
    console.log(`CHANGE event on ${$event.model.id}: `, $event);
    // console.log(`additional dataType: ${$event.model.additional.dataType}`);
    this.inputModel = this.formService.findById('startReceiveDate', this.formModel) as DynamicDatePickerModel;
    console.log('additional dataType:', this.inputModel);
    this.inputModel.disabledUpdates.next(true);
  }

  onFocus($event) {
    console.log(`FOCUS event on ${$event.model.id}: `, $event);
  }

  onPEvent($event) {
    console.log(`PEvent event on ${$event.model.id}: `, $event);
  }


  /*******************************************/

  onConfigFilter() {
    this.loadFilter();
    this.showConfig = true;
  }

  onSaveFilter() {
    let valSearch = '';
    let valField = '';
    valSearch = this.selectedColumns.map((k, v) => {
      return k.Value;
    }).join(',');
    if (valSearch == '') {
      valSearch = this.columns[0].Value;
    }

    if (this.isConfigField) {
      valField = this.selectedFieldColumns.map((k, v) => {
        return k.Value;
      }).join(',');
      if (valField == '') {
        valField = this.columns[0].Value;
      }

    }

    const filterValue = { valSearch: valSearch, valField: valField };
    this.settingService.setFiltering(filterValue, this.usePage);
    this.loadFilter();
    this.popup.showMsgSuccess('Success Message', 'Save success');
    this.showConfig = false;
  }

  onSearchByColumn(sendData: string = 'generated') {
    this.searchQuery = [];
    this.fieldList = [];
    this.outputModel = [];
    const resultItems = this.searchRowsDisplay.filter(t => t.isChecked);
    this.fieldList = this.fieldRows;

    resultItems.forEach(f => {
      let isCanPush = true;
      let value1 = f.keyWord1;
      let value2 = f.keyWord2;
      let containValue = f.containTerm;

      if (f.datetimeTerm > -1) {
        const pipe = new DatePipe('en-US');
        if (f.datetimeTerm > 0) {
          value1 = pipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
          value2 = pipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
        } else {
          value1 = pipe.transform(f.dateTime1, 'yyyy-MM-dd HH:mm');
          value2 = pipe.transform(f.dateTime2, 'yyyy-MM-dd HH:mm');
        }
      } else if (f.statusTerm > -1) {
        value1 = f.statusSelect.join(',');
        if (!value1) {
          isCanPush = false;
        }
      } else if (f.fixStatusTerm > -1) {
        // if (this.isDeleted) {
        //   value1 = '2';
        // } else {
        //   value1 = '1,3';
        // }
      } else if (f.radioTerm > -1) {
        value1 = this.radioValue;
        this.deletePageRadio = this.radioValue;
      } else if (f.fixBoolTerm > -1) {
        this.outputModel.push({ key: f.columnName, value: value1 });
        isCanPush = false;
      } else if (f.isHeadDropDown) {
        containValue = 0;
        value1 = value1.length ? value1.join(',') : value1;
      } else if (f.isSerial) {
        // containValue = 0;
        isCanPush = false;
        f.serialNumbers.forEach(s => {
          this.searchQuery.push({
            columnName: 'SerialNumber' + s.index,
            opContain: containValue,
            opDateTime: f.datetimeTerm,
            opStatus: f.statusTerm,
            opFixStatus: f.fixStatusTerm,
            opSelection: f.selectionTerm,
            keyword1: s.value,
            keyword2: value2
          });
        });
      }

      if (isCanPush) {
        this.searchQuery.push({
          columnName: f.columnName,
          opContain: containValue,
          opDateTime: f.datetimeTerm,
          opStatus: f.statusTerm,
          opFixStatus: f.fixStatusTerm,
          opSelection: f.selectionTerm,
          opRadio: f.radioTerm,
          isSearchEqual: f.isSearchEqual,
          keyword1: value1,
          keyword2: value2
        });
      }
    });

    this.sendInfo.next({ status: sendData });
  }

  onEnterSerial(event: any, current: number) {
    const element = event.srcElement.nextElementSibling;
    if (element) {
      element.focus();
    }
    this.onSearchByColumn();
    // if (current == this.maxSerialNumber) {
    // }
  }

  onCheckboxChange(event: any, value: any) {
    const head = this.searchRowsDisplay.find(f => !f.isNormalHead && f.isHeadDropDown);
    if (value.isSerial || value.columnName.toLowerCase().startsWith('serial')) {
      const anySerialCheck = this.searchRowsDisplay.some(s => s.columnName.toLowerCase().startsWith('serial') && s.isChecked);
      if (!event && !anySerialCheck) {
        this.searchRowsDisplay = this.searchRows;
        head.isChecked = false;
        this.isCheckSerial = false;
      } else {
        const idx = (this.searchRowsDisplay.findIndex(f => f.isSearchEqual) > -1 ? 2 : 1);
        this.searchRowsDisplay = this.searchRows.slice(0, this.searchRowsDisplay.findIndex(f => f.isSerial) + idx);
        head.isChecked = true;
        this.isCheckSerial = true;
      }
    } else if (value.isSearchEqual && this.searchRowsDisplay.findIndex(f => f.isSerial) > -1) {
      if (!event) {
        this.searchRowsDisplay = this.searchRows;
        head.isChecked = false;
        this.isCheckSerial = false;
      } else {
        this.searchRowsDisplay = this.searchRows.slice(0, this.searchRowsDisplay.findIndex(f => f.isSearchEqual) + 1);
        head.isChecked = true;
        this.isCheckSerial = true;
      }
    }
  }

  public loadFilter() {

    if (this.fullJSON
      && this.fullJSON.length > 0) {
      const tempCols = this.generateConfigItems(this.fullJSON);
      this.columns = [...tempCols];
      if (this.projectConfig != undefined) {
        this.columns = ProjectConfigSet.setProjectConfigItemToColumn(this.projectConfig, this.columns);
        this.selectedColumns = ProjectConfigSet.setProjectConfigItemToColumn(this.projectConfig, this.selectedColumns);
      }
      this.generateSearchRow(this.selectedColumns);
      this.firstTriggerSearch();
    } else {
      if (this.isConfigField) {
        const headReq = this.orderService.getDefColumn(this.searchJSON);
        const headFieldReq = this.orderService.getDefColumn(this.fieldJSON);

        forkJoin([headReq, headFieldReq]).subscribe((res: any) => {
          const headList = Object.assign([], res[0].Result);
          if (headList.length > 0) {
            const tempCols = this.generateConfigItems(headList);
            this.columns = [...tempCols];
            if (this.projectConfig != undefined) {
              this.columns = ProjectConfigSet.setProjectConfigItemToColumn(this.projectConfig, this.columns);
            }
            this.generateSearchRow(this.selectedColumns);
          }
          const headFieldList = Object.assign([], res[1].Result);
          if (headFieldList.length > 0) {
            let tempFieldCols = this.generateConfigFieldItems(headFieldList);
            if (this.projectConfig != undefined) {
              tempFieldCols = ProjectConfigSet.setProjectConfigItemToColumn(this.projectConfig, tempFieldCols);
              this.selectedFieldColumns = ProjectConfigSet.setProjectConfigItemToColumn(this.projectConfig, this.selectedFieldColumns);
            }
            this.fieldColumns = [...tempFieldCols];
            this.generateFieldRow();
          }
          this.firstTriggerSearch();
        });
      } else {
        this.orderService.getDefColumn(this.searchJSON).subscribe((res: any) => {
          const headList = Object.assign([], res.Result);
          if (headList.length > 0) {
            const tempCols = this.generateConfigItems(headList);
            this.columns = [...tempCols];
            if (this.projectConfig != undefined) {
              this.columns = ProjectConfigSet.setProjectConfigItemToColumn(this.projectConfig, this.columns);
            }
            this.generateSearchRow(this.selectedColumns);
          }
          this.firstTriggerSearch();
        });
      }
    }

  }
  firstTriggerSearch() {
    this.onSearchByColumn('firsttrigger');
  }


  generateSearchRow(selectedColumns: any) {
    // this.searchRows = [];
    const tempSearchRows = [];
    const now = new Date();
    selectedColumns.forEach(f => {
      const searchRow = this.setSearchRow(f, now);
      if (searchRow) {
        tempSearchRows.push(searchRow);
      }
    });

    if (tempSearchRows.some(s => s.isSerial)) {
      const obj = tempSearchRows.find(s => s.isSerial);
      obj.serialNumbers.splice(this.maxSerialNumber, 3 - this.maxSerialNumber);
    }

    this.searchRows = [...tempSearchRows];
    this.searchRowsDisplay = this.searchRows;

  }



  public onInitQuery(customQuery: any, isFromImport: boolean) {
    this.canConfig = false;
    let hasSerial = false;
    this.isOrderCanEdit = !(!this.isOrderCanEdit && isFromImport);
    if (customQuery) {
      Object.keys(customQuery).forEach(f => {
        let addInSelection = false;
        if (customQuery[f]) {
          if (f == 'itemCode') {
            console.log(this.selectedColumns);
            console.log(this.fullJSON);
            const itemEdit = this.selectedColumns.find(s => s.DataType == 'fixheaddropdown');
            if (itemEdit) {
              itemEdit.DefValue = [customQuery[f]];
              itemEdit.IsSelected = true;
            }
          } else if (f.startsWith('serialNumber')) {
            let itemEdit = this.selectedColumns.find(s => s.ColumnName == 'SerialNumber');
            if (!itemEdit) {
              itemEdit = this.columns.find(s => s.ColumnName && s.ColumnName.toLowerCase() == f.toLowerCase());
              addInSelection = true;
            }
            if (itemEdit) {
              if (!itemEdit.DefValue) { itemEdit.DefValue = []; }
              itemEdit.DefValue.push({ index: (itemEdit.DefValue.length + 1), value: customQuery[f] });
              itemEdit.IsSelected = true;
              hasSerial = true;
              if (addInSelection) {
                this.selectedColumns.push(itemEdit);
              }
            }
          } else if (f.startsWith('controlLevel') || (f.indexOf('Date') > -1)) {
            let itemEdit = this.selectedColumns.find(s => s.ColumnName && s.ColumnName.toLowerCase() == f.toLowerCase());
            if (!itemEdit) {
              itemEdit = this.columns.find(s => s.ColumnName && s.ColumnName.toLowerCase() == f.toLowerCase());
              addInSelection = true;
            }
            if (itemEdit) {
              if ((f.indexOf('Date') > -1)) {
                const dd = new Date(customQuery[f]);
                itemEdit.DefValue = dd;
              } else {
                itemEdit.DefValue = customQuery[f];
              }
              itemEdit.IsSelected = true;
              if (addInSelection) {
                this.selectedColumns.push(itemEdit);
              }
            }
          } else if (f == 'qualityTypeCode' || f == 'locationCode') {
            let itemEdit = this.selectedColumns.find(s => s.ColumnName && s.ColumnName.toLowerCase() == f.toLowerCase());
            if (!itemEdit) {
              itemEdit = this.columns.find(s => s.ColumnName && s.ColumnName.toLowerCase() == f.toLowerCase());
              addInSelection = true;
            }
            if (itemEdit) {
              const val = customQuery[f];
              itemEdit.DefValue = (val.indexOf('(') > -1 && val.indexOf(')') > -1)
                ? val.slice(val.indexOf('(') + 1, val.indexOf(')')).trim()
                : val;
              itemEdit.IsSelected = true;
              if (addInSelection) {
                this.selectedColumns.push(itemEdit);
              }
            }
          }
        }
      });
      this.generateSearchRow(this.selectedColumns);
      if (hasSerial) {
        this.searchRowsDisplay = this.searchRows.slice(0, this.searchRowsDisplay.findIndex(f => f.isSerial) + 1);
        this.isCheckSerial = true;
      }
      this.onSearchByColumn();
    }
  }

  public onClearInitQuery() {
    this.canConfig = true;
    if (this.fullJSON
      && this.fullJSON.length > 0) {
      const tempCols = this.generateConfigItems(this.fullJSON);
      this.columns = [...tempCols];
      if (this.projectConfig != undefined) {
        this.columns = ProjectConfigSet.setProjectConfigItemToColumn(this.projectConfig, this.columns);
        this.selectedColumns = ProjectConfigSet.setProjectConfigItemToColumn(this.projectConfig, this.selectedColumns);
      }
      this.generateSearchRow(this.selectedColumns);
    }
  }

  setSearchRow(f: any, date: any) {
    let stringSearch = -1;
    let statusSearch = -1;
    let dateTimeSearch = -1;
    let fixStatusSearch = -1;
    let isHeadDropDown = false;
    let fixBool = -1;
    let fixRadio = -1;
    let selectionSearch = -1;
    let isFix = false;
    let isSerial = false;
    let isNotChange = false;
    let isNormalHead = true;
    let isCheck = false;
    let isSearchEqual = false;
    let items: any[] = [];
    const statusSelect: any[] = [];
    let selectItems: any[] = [];
    let serialObj = [{ index: 1, value: '' }
      , { index: 2, value: '' }
      , { index: 3, value: '' }];
    let value1 = '';
    const value2 = '';
    switch (f.DataType.toLowerCase()) {
      case 'datetime': dateTimeSearch = 0;
        if (f.DefValue) {
          date = f.DefValue;
          isCheck = true;
          isNotChange = !this.isOrderCanEdit;
        }
        break;
      case 'status': statusSearch = 0;
        items = f.StatusItems;
        value1 = '1';
        break;
      case 'fixstatus': fixStatusSearch = 0;
        items = f.StatusItems;
        value1 = f.DefaultStatus;
        isCheck = true;
        isFix = true;
        break;
      case 'fixbool': fixBool = 0;
        value1 = f.DefaultValue;
        isCheck = true;
        isFix = true;
        isNormalHead = false;
        break;
      case 'fixheaddropdown': isHeadDropDown = true;
        isNormalHead = false;
        f.Value = this.codeList[0].code;
        if (f.DefValue) {
          value1 = f.DefValue;
          isCheck = true;
          isNotChange = !this.isOrderCanEdit;
        }
        break;
      case 'headdropdowndate': isHeadDropDown = true;
        isNormalHead = false;
        dateTimeSearch = 0;
        f.Value = this.orderdateList[0].code;
        break;

      case 'serial': stringSearch = 0;
        isSerial = true;
        if (f.DefValue) {
          serialObj = f.DefValue;
          isCheck = true;
          isNotChange = !this.isOrderCanEdit;
        }
        break;

      case 'fixradio': fixRadio = 0;
        items = f.StatusItems;
        value1 = f.DefaultStatus;
        this.radioValue = f.DefaultStatus;
        this.deletePageRadio = f.DefaultStatus;
        isCheck = true;
        isFix = true;
        break;

      case 'selection': selectionSearch = 0;
        selectItems = f.Items;
        break;
      case 'equals': isSearchEqual = true;
        if (f.DefValue) {
          value1 = f.DefValue;
          isCheck = true;
          isNotChange = !this.isOrderCanEdit;
        }
        break;
      default: stringSearch = 0;
        if (f.DefValue) {
          value1 = f.DefValue;
          isCheck = true;
          isNotChange = !this.isOrderCanEdit;
        }
    }

    const item = {
      columnName: f.Value,
      headingName: f.HeadingName,
      optionText: f.OptionText,
      headingNameTrue: f.HeadingNameTrue,
      headingNameFalse: f.HeadingNameFalse,
      isNormalHead: isNormalHead,
      isHeadDropDown: isHeadDropDown,
      keyWord1: value1,
      keyWord2: value2,
      dateTime1: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0),
      dateTime2: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),
      isChecked: isCheck,
      statusItems: items,
      statusSelect: statusSelect,
      containTerm: stringSearch,
      datetimeTerm: dateTimeSearch,
      statusTerm: statusSearch,
      fixStatusTerm: fixStatusSearch,
      fixBoolTerm: fixBool,
      radioTerm: fixRadio,
      isFix: isFix,
      isSerial: isSerial,
      serialNumbers: serialObj,
      selectionTerm: selectionSearch,
      selectionItem: selectItems,
      isNotChange: isNotChange,
      isSearchEqual: isSearchEqual
    };
    return item;
  }

  generateFieldRow() {

    const tempFieldRows = [];
    this.selectedFieldColumns.forEach(f => {

      tempFieldRows.push(
        {
          columnName: f.Value,
          headingName: f.HeadingName,
          isChecked: false,
          isSort: false
        });
    });
    this.fieldRows = [...tempFieldRows];



  }

  generateConfigItems(list: any[]) {
    // this.selectedColumns = [];
    const tempSelectedColumns = [];
    const userFilter = this.settingService.getFiltering(this.usePage);
    let filterData: string[];
    if (userFilter != undefined) {
      filterData = userFilter.valSearch.split(',');
    }
    list.map((d) => {
      Object.assign(d, { Value: d.ColumnName, IsSelected: false, StatusItems: d.Items, DefValue: '' });

      if (filterData != undefined) {
        if ((filterData.some(f => f == d.ColumnName))
          || d.DataType.startsWith('fix')) {
          d.IsSelected = true;
          tempSelectedColumns.push(d);
        }
      }


    });

    // Case First Time
    if (tempSelectedColumns.length < 1) {
      list.map((k) => {
        if ((k.Mandatory && !k.IsSelected)
          || k.DataType.startsWith('fix')) {
          k.IsSelected = true;
          tempSelectedColumns.push(k);
        }
      });
    }

    this.selectedColumns = [...tempSelectedColumns];



    return list;
  }

  generateConfigFieldItems(list: any[]) {
    // this.selectedFieldColumns = [];
    const tempSelectedFieldColumns = [];
    const userFilter = this.settingService.getFiltering(this.usePage);
    let filterData: string[];
    if (userFilter != undefined) {
      filterData = userFilter.valField.split(',');
    }
    list.map((d) => {
      Object.assign(d, { Value: d.ColumnName, IsSelected: false });
      if (filterData != undefined) {
        if (filterData.some(f => f == d.ColumnName)) {
          d.IsSelected = true;
          tempSelectedFieldColumns.push(d);
        }
      }
    });

    // Case First Time
    if (tempSelectedFieldColumns.length < 1) {
      list.map((k) => {
        if (k.Mandatory && !k.IsSelected) {
          k.IsSelected = true;
          tempSelectedFieldColumns.push(k);
        }
      });
    }

    this.selectedFieldColumns = [...tempSelectedFieldColumns];

    return list;
  }

  onChangeOrderStatusFilter(event: any) {
    if (event !== undefined && event !== null) {
      this.sendInfo.next({ status: 'radioChange' });
    }
  }


}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  @Input() message;
  @Input() buttons: any;
  @Input() checkboxGroup: any;

  private canConfirm = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.checkboxGroup) {
      this.canConfirm = this.checkboxGroup.require && this.checkboxGroup.child.some(s => s.value);
    }
  }

  onCheckboxChange() {
    if (this.checkboxGroup) {
      this.canConfirm = this.checkboxGroup.require && this.checkboxGroup.child.some(s => s.value);
    }
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { environment as env } from '@env/environment';
import { NbMenuService, NbMenuItem } from '@nebular/theme';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

    public routeUrl: string;
    public pageTitle: string;
    public breadcrumbs: Array<{
        name: string;
        url: string
    }> = [];

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public title: Title,
        private menuService: NbMenuService
    ) {
        this.routeUrl = this.router.url;
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.breadcrumbs = [];
                this.parseRoute(this.router.routerState.snapshot.root);
            }
        });
    }

    public goHome() {
        this.menuService.collapseAll();
        this.router.navigate(['/modules']);
    }

    private parseRoute(node: ActivatedRouteSnapshot) {

        let url = '';
        if (node.data['breadcrumb']) {
            if (node.url.length) {
                let urlSegments: UrlSegment[] = [];
                node.pathFromRoot.forEach((routerState) => {
                    urlSegments = urlSegments.concat(routerState.url);
                });
                url = urlSegments.map((urlSegment) => {
                    return urlSegment.path;
                }).join('/');
            }

            if (node.params.name) {
                this.breadcrumbs.push({
                    name: node.params.name,
                    url: '/' + url
                });
            } else {
                this.breadcrumbs.push({
                    name: node.data['breadcrumb'],
                    url: '/' + url
                });
            }
        }
        if (node.firstChild) {
            this.parseRoute(node.firstChild);
        }
    }

}

import { Component, OnInit, Input } from '@angular/core';
import { NbSidebarService, NbMenuService } from '@nebular/theme';
import { LayoutService } from '@app/core/layout.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  logoPath = environment.service.core.identity + '/icon.png';

  constructor(
    public router: Router,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    private menuService: NbMenuService
  ) {
  }

  ngOnInit() {
  }

  public goHome() {
    this.menuService.collapseAll();
    this.router.navigate(['/modules']);
}

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Status } from '@app/shared/models/tracking/status.model';
import { NumberSymbol } from '@angular/common';
import { RequestOptions, Headers } from '@angular/http';
import { headersToString } from 'selenium-webdriver/http';
import { Header } from 'primeng/primeng';
import { ReportHelper } from '@app/core/helper/report/report.helper';
import { QualityTypeModel } from '@app/shared/models/qualitytype-model';
import { EncryptionService } from '@app/core/authentication/encryption.service';
import { HttpHelper } from '@app/core/helper/http/http.helper';

@Injectable({
  providedIn: 'root'
})
export class ReceiveService {

  constructor(private http: HttpClient
    , private httpHelper: HttpHelper
    , private encryptService: EncryptionService) { }

  env = environment;

  /*Receive Plan */


  getAllReceivePlanHead() {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receiveplans');
  }

  getReceivePlanHead(id?: any) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receiveplans/' + id);
  }

  getReceivePlanHeadForTransfer(id?: any) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receiveplans/transfer/' + id);
  }

  getReceivePlanDetailPaging(data?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receiveplans/itemspaging', data);
  }

  getReceivePlanItemByPlanId(planId: number) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receiveplans/items/' + planId);
  }


  postReceiveItemMapping(planItemId: number, receiveItemId: number) {
    return this.http.auth().post(environment.service.wms.receiving + `/receiveplans/receiveitemmapping/${planItemId}/${receiveItemId}`, {});

  }

  delReceiveItemMapping(receiveItemId: number) {
    return this.http.auth()
      .delete(environment.service.wms.receiving + `/receiveplans/receiveitemmapping/${receiveItemId}`);
  }

  getReceiveSerialFromPlan(id?: any) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receiveplans/checkserial/' + id);
  }

  getReceivePlanByItemcode(body: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receiveplans/searchitem', body);
  }

  PostMoveToReceiveHead(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receiveplans/movetoreceive', body);
  }

  postSearchReceivePlanHead(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receiveplans/search', body);
  }
  postSearchReceivePlanHeadInDeletePage(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receiveplans/searchDelete', body);
  }


  postCheckReceiveHead(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receiveplans/checkreceive', body);
  }

  removeMultipleReceivePlans(ids: number[]) {
    return this.http.auth()
      .put(environment.service.wms.receiving + '/receiveplans/delete', ids);
  }

  putRenewMultipleReceivePlans(body?: any) {
    return this.http.auth()
      .put(environment.service.wms.receiving + '/receiveplans/recall/', body);
  }

  postCreateReceivePlanHead(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receiveplans', body);
  }

  putUpdateReceivePlanHead(id: number, body?: any) {
    return this.http.auth()
      .put(environment.service.wms.receiving + '/receiveplans/' + id, body);
  }

  postCreateReceivePlanItem(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receiveplans/items', body);
  }

  putUpdateReceivePlanItem(id: number, body?: any) {
    return this.http.auth()
      .put(environment.service.wms.receiving + '/receiveplans/items/' + id, body);
  }

  removeReceivePlanItem(id: number) {
    return this.http.auth()
      .delete(environment.service.wms.receiving + '/receiveplans/items/' + id);
  }

  removeReceivePlanItems(ids: number[]) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receiveplans/itemremoves', ids);
  }

  getPlanNewPo() {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receiveplans/genreceivenumber');
  }

  getNewReceivePlanByIdPdf(id: any) {
    return this.http.auth()
      .disableApiResponse()
      .get(environment.service.wms.receiving + '/receiveplans/headPdf/' + id, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }

  postSearchNewReceivePlanHeadPdf(body?: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.receiving + '/receiveplans/searchPdf', body, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }

  postGenPlanCheckReportTable(body: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.receiving + '/receiveplans/planCheckPdf?', body, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }







  /*Receive */

  getReceiveListFromReceivingService() {
    return this.http
      .auth()
      .get(environment.service.wms.receiving + '/receives');
  }

  getNewReceiveListHead() {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives');
  }

  getNewReceiveHead(id?: any) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/' + id);
  }

  getNewReceiveItem(id?: any) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/' + id + '/items');
  }


  getNewReceiveItemConfirming(id?: any) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/receiveitemConfirming/' + id);
  }



  postReceiveItemByHeadId(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/itemspaging', body);
  }

  postReceiveItemByHeadIdFilter(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/itemspagingbyfilter', body);
  }


  postCreateNewReceiveHead(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/draft', body);
  }



  postCreateNewReceiveItem(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/items', body);
  }

  postManualImportNewReceive(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/import', body);
  }

  postManualImportNewReceiveContinue(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/import', body);
  }

  putUpdateReceiveHead(id: number, body?: any) {
    return this.http.auth()
      .put(environment.service.wms.receiving + '/receives/draft/' + id, body);
  }

  putUpdateReceiveItem(id: number, body?: any) {
    return this.http
      .auth()
      // .activity('w', 'Inbound Management', 'Input Receiving', '#' + id, 'receives/' + id)
      .put(environment.service.wms.receiving + '/receives/items/' + id, body);
  }


  putConfirmReceive(id: number, body?: any) {
    return this.http
      .auth()
      .put(environment.service.wms.receiving + '/receives/confirm/' + id, body);
  }

  putConfirmReceives(id: string, body?: any) {
    body = this.httpHelper.objToHttpParams({ ReceiveId: id });
    return this.http
      .auth()
      .put(environment.service.wms.receiving + '/receives/confirms?' + body, { });
  }

  putRenewReceive(id: number, body?: any) {
    return this.http.auth()
      .put(environment.service.wms.receiving + '/receives/recall/' + id, body);
  }

  putRenewMultipleReceives(body?: any) {
    return this.http.auth()
      .put(environment.service.wms.receiving + '/receives/recall/', body);
  }


  postSearchNewReceiveHead(body?: any) {
    return this.http.auth()
      // .encodeApiResponse()
      .post(environment.service.wms.receiving + '/receives/search', body);
    //  .pipe(map((mp: any) => {
    //   return this.encryptService.decrypt(mp);
    //  }));
  }

  postSearchNewReceiveHeadInDeletePage(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/searchDelete', body);
  }

  postsearchReceivingPage(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/searchReceivingPage', body);
  }

  postSearchNewGroupReceiveHead(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/searchgroup', body);
  }

  postSearchNewGroupReceivePutAwayHead(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/searchputaway', body);
  }

  getNewPo() {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/genreceivenumber');
  }

  getCheckPo(poNumber: any) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/checkpo/' + poNumber);
  }



  postSearchNewReceiveHeadPdf(body?: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.receiving + '/receives/searchPdf', body, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }

  getNewReceiveByIdPdf(id: any) {
    return this.http.auth()
      .disableApiResponse()
      .get(environment.service.wms.receiving + '/receives/headPdf/' + id, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }



  postSearchNewReceiveDetailPdf(body?: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.receiving + '/receives/searchDetailPdf', body, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }


  getNewReceivePutAwayByDetailPdf(id: number) {
    return this.http.auth()
      .disableApiResponse()
      .get(environment.service.wms.receiving + '/receives/getPutAwayPdf/' + id, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }

  postSearchNewReceivePutAwayPdf(body?: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.receiving + '/receives/searchPutAwayPdf', body, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }




  getReceiveHistoryPdf(id: string, iframeId: string = 'iframe_pdf') {
    return this.http.auth()
      .disableApiResponse()
      .get(environment.service.wms.receiving + '/receives/historyPdf/' + id, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        this.addFileToIframe(resp, iframeId);
      }
      );
  }

  getReceiveHistoryPdfPopup(id: string) {
    return this.http.auth()
      .disableApiResponse()
      .get(environment.service.wms.receiving + '/receives/historyPdf/' + id, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }

  getByControlLevel2ByIdPdf(id: number, keyword: any) {
    return this.http.auth()
      .disableApiResponse()
      .get(environment.service.wms.receiving + '/receives/controllevel2PDF?receiveId=' + id + '&keyword=' + keyword, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }

  getByControlLevel3ByIdPdf(id: number, keyword: any) {
    return this.http.auth()
      .disableApiResponse()
      .get(environment.service.wms.receiving + '/receives/controllevel3PDF?receiveId=' + id + '&keyword=' + keyword, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }


  getGenReceiveWarrantyPDF(body: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.receiving + '/receives/warrantyPDF?', body, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }

  getGenReceiveWarrantyExcel(body: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.receiving + '/receives/report?', body, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        this.downloadFile(resp);
        // ReportHelper.exportToPDFPopup(resp);
      }
      );
  }

  getReceiveHistory(id: string) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/history/' + id);
  }


  getAutocompleteReceiveTemp(body: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/autocompletereceivetemp', body);
  }

  getAutocompleteSupplier(subcode: string) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/autocompletesupplier/' + subcode);
  }

  getAutocompleteSpecialProduct(description: string) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/autocompletespecialproduct/' + description);
  }

  getSpecialProductByKeyword(term: string) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/specialProduct/' + term);
  }


  getDefColumn(table: string) {
    return this.http.auth()
      // .encodeApiResponse()
      .get(environment.service.wms.receiving + '/receives/receivejson/' + table + '?t=' + Date.now());
    // .pipe(
    //  map((mp: any) => {
    //   return this.encryptService.decrypt(mp);
    //  })
    // );
  }

  getAllReceiveType() {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/receiveTypes');
  }
  getAllReceiveQuality() {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/receiveQuality');
  }

  putReceiveQuality(body: QualityTypeModel) {
    return this.http.auth()
      .put(environment.service.wms.receiving + '/receives/receiveQuality', body);
  }

  putReceiveQualitySeq(body: any) {
    return this.http.auth()
      .put(environment.service.wms.receiving + '/receives/reOrderReceiveQuality', body);
  }



  getAllSpecialProductType() {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/specialproducttype');
  }

  getUnconfirmReceive() {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/unconfirmcount');
  }

  removeReceive(id: number) {
    return this.http.auth()
      .delete(environment.service.wms.receiving + '/receives/' + id);
  }

  removeMultipleReceiveItems(ids: number[]) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/itemremoves', ids);
  }

  removeMultipleReceives(ids: number[]) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/', ids);
  }



  removeReceiveItem(id: number) {
    return this.http.auth()
      .delete(environment.service.wms.receiving + '/receives/items/' + id);
  }

  removeReceiveFile(id: number) {
    return this.http.auth()
      .delete(environment.service.wms.receiving + '/receives/file/' + id);
  }

  removeReceiveInvoice(id: number) {
    return this.http.auth()
      .delete(environment.service.wms.receiving + '/receives/invoice/' + id);
  }

  getAutoComItems(term: string) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/items/autocomplete/' + term);
  }

  getItem(id: number) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/items/' + id);
  }

  getAutoComLocation(term: string) {
    return this.http.auth()
      .get(environment.service.wms.receiving + '/receives/autocompletelocation/' + term);
  }

  postGenReceiveReportByOrderIds(data: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/reportreceiveids', data);
  }


  postDownloadReceiveReportList(datas: any[], i: number) {
    if (i < datas.length) {
      this.http.auth().disableApiResponse()
        .post(environment.service.wms.receiving + '/receives/report', datas[i], { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
          this.downloadFile(resp);
          const me = this;
          setTimeout(function () {
            me.postDownloadReceiveReportList(datas, (i + 1));
          }, 1000);
        });
    } else {
      return true;
    }
  }

  postDownloadReceiveReport(data: any) {
    return this.http.auth().disableApiResponse()
      .post(environment.service.wms.receiving + '/receives/report', data, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        this.downloadFile(resp);
      }
      );
  }

  postDownloadPackingList(data: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.receiving + '/receives/reportpacking', data, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      });
  }

  postGenReceiveWarranty(data: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/warranty', data);
  }

  postGenReceiveReportTable(data: any) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/reportTable', data);
  }



  postGenReceivingCheckReportTable(body: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.receiving + '/receives/receiveCheckPdf?', body, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }



  postReceiveConfirms(receiveItemIds: number[]) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/receivingcomfirms', receiveItemIds);
  }





  public downloadFile(data: any) {
    let name = data.headers.get('content-disposition');
    name = name.substring(name.indexOf('=') + 1).replace(/[:*?"<>|/\\]/g, '');
    name = name.substring(0, name.indexOf(';'));
    const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = name;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    URL.revokeObjectURL(url);
  }

  public addFileToIframe(data: any, iframeId: string) {

    // var type = data.headers.get('Content-Type');
    // let name: string;
    // name = data.headers.get('content-disposition');
    // let name = name.substring(name.indexOf('=') + 1);
    const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
    const url = window.URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.width = '100%';
    iframe.height = '700px';
    iframe.src = url;
    document.getElementById(iframeId).innerHTML = '';
    document.getElementById(iframeId).appendChild(iframe);
  }


  public exportToPDFPopup(data: any): void {
    // let name: string;
    // name = data.headers.get('content-disposition');
    // let name = name.substring(name.indexOf('=') + 1);
    const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
    const url = URL.createObjectURL(blob);
    // window.open(url, '_blank');

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  // for merge address field
  public mergeAddress(data: any): void {
    data.receiveSupplierAddress = ' ';

    if (data.address1 !== null && data.address1 !== undefined) {
      data.receiveSupplierAddress += ' ' + data.address1;
    }
    if (data.address2 !== null && data.address2 !== undefined) {
      data.receiveSupplierAddress += ' ' + data.address2;
    }
    if (data.address3 !== null && data.address3 !== undefined) {
      data.receiveSupplierAddress += ' ' + data.address3;
    }
    if (data.address4 !== null && data.address4 !== undefined) {
      data.receiveSupplierAddress += ' ' + data.address4;
    }
    if (data.address5 !== null && data.address5 !== undefined) {
      data.receiveSupplierAddress += ' ' + data.address5;
    }

    data.receiveSupplierAddress = data.receiveSupplierAddress.trim();
    data.receiveSupplierZipCode = data.address6;
    data.receiveSupplierAddressEn = ' ';

    if (data.addressEn1 !== null && data.addressEn1 !== undefined) {
      data.receiveSupplierAddressEn += ' ' + data.addressEn1;
    }
    if (data.addressEn2 !== null && data.addressEn2 !== undefined) {
      data.receiveSupplierAddressEn += ' ' + data.addressEn2;
    }
    if (data.addressEn3 !== null && data.addressEn3 !== undefined) {
      data.receiveSupplierAddressEn += ' ' + data.addressEn3;
    }
    if (data.addressEn4 !== null && data.addressEn4 !== undefined) {
      data.receiveSupplierAddressEn += ' ' + data.addressEn4;
    }
    if (data.addressEn5 !== null && data.addressEn5 !== undefined) {
      data.receiveSupplierAddressEn += ' ' + data.addressEn5;
    }

    data.receiveSupplierAddressEn = data.receiveSupplierAddressEn.trim();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Supplier } from '@app/shared/models/supplier/supplier.model';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(
    private http: HttpClient
  ) { }

  postCreateMaster(supplier: Supplier) {
    return this.http.auth()
      .post(environment.service.wms.item + '/suppliers', supplier);
  }

  postCreate(supplier: Supplier) {
    return this.http.auth()
      .post(environment.service.wms.project + '/suppliers', supplier);
  }

  putUpdate(id: number, body: any) {
    return this.http.auth()
      .put(environment.service.wms.project + '/suppliers/' + id, body);
  }

  searchSupplierBySupCode(supCode: string) {
    return this.http.auth()
      .post(environment.service.wms.receiving + '/receives/supplier/', supCode);
  }

  getSupplierById(id: number) {
    return this.http.auth()
      .get(environment.service.wms.project + '/suppliers/' + id);
  }


  postSearchSupplier(body: any) {
    return this.http.auth()
      .post(environment.service.wms.item + '/suppliers/searchsupplier/', body);
  }

  postManualImportSupplier(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.item + '/suppliers/import', body);
  }

  postManualImportSupplierToProject(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.project + '/suppliers/import', body);
  }


  getSupplierHistory(id: string) {
    return this.http.auth()
      .get(environment.service.wms.project + '/suppliers/history/' + id);
  }

  getSupplierHistoryPDF(id: string, iframeId: string = 'iframe_pdf') {
    return this.http.auth()
      .disableApiResponse()
      .get(environment.service.wms.project + '/suppliers/historyPdf/' + id, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        this.addFileToIframe(resp, iframeId);
      }
      );
  }


  autoComSupplier(keyword: string) {
    return this.http.auth()
      // .get(environment.service.wms.item + '/suppliers/autocomsupplier/' + keyword);
      .get(environment.service.wms.item + '/suppliers/autocomplete/' + keyword);

  }

  public addFileToIframe(data: any, iframeId: string) {

    // var type = data.headers.get('Content-Type');
    // let name = data.headers.get('content-disposition');
    // var name = name.substring(name.indexOf('=') + 1);
    const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
    const url = window.URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.width = '100%';
    iframe.height = '700px';
    iframe.src = url;
    document.getElementById(iframeId).innerHTML = '';
    document.getElementById(iframeId).appendChild(iframe);
  }

}

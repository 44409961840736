import { NbMenuItem } from '@nebular/theme';

export const USER_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'User',
    link: '/modules/admin/user'
  },
  {
    title: 'User Manage',
    link: '/modules/admin/user/usermanage'
  },
  {
    title: 'Role',
    link: '/modules/admin/user/roles'
  },
];

import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { PopupModalComponent } from '@app/shared/components/popup/popup-modal/popup-modal.component';
import { UserSettingService } from '@app/shared/services/user-setting.service';
import { ProjectConfigSet } from '@app/core/helper/set-value/project-config-set';
import { OrderService } from '@app/modules/outbound/order/order.service';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { FilterModel } from '@app/shared/models/filter/filter-model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-dynamic-filter',
  templateUrl: './dynamic-filter.component.html',
  styleUrls: ['./dynamic-filter.component.scss']
})
export class DynamicFilterComponent implements OnInit {


  @Input() projectConfig: any;
  @Input() usePage: any;
  @Input() jsonModel: FilterModel[];
  @Input() fieldList: any[];


  @Output() sendInfo: EventEmitter<{ status: string }> = new EventEmitter<{ status: string }>();

  tabs = [
    {
      title: 'Filter', active: true, icon: 'nb-search'
    },
    {
      title: 'Settings', active: false, icon: 'nb-gear', badgeText: 0, badgeStatus: 'success'
    }];

  tempJsonModel: FilterModel[];
  selectedColumns: any[];
  selectedFields: any[];
  columns: any[];
  fields: any[];

  public searchQuery: any[];

  constructor(private orderService: OrderService,
    private popup: PopupModalComponent,
    private settingService: UserSettingService
  ) {

  }

  ngOnInit() {
    if (this.projectConfig && this.fieldList) {
      this.fieldList = ProjectConfigSet.setProjectConfigItemToColumn(this.projectConfig, this.fieldList);
    }

    if (this.jsonModel) {
      this.columns = this.jsonModel.map(m => {
        return Object.assign({ Key: m.HeadingName, Value: m.ColumnName });
      });
    }


    this.jsonModel.map(m => {
      if (m.Default) {
        m.Val = m.Default;
      }
      if (m.IsCustom) {
        m.ColumnName = m.Optional;
      }
      if (m.DataType == 'input.multiple.dropdown' && !m.IsCustom) {
        m.Optional = 1;
      }
      if (m.DataType == 'datetime') {
        m.IsDateTime = true;
      }
      if (m.ColumnName.endsWith('Id')) {
        m.IsNumber = true;
      }
    });

    this.setSelectedColumns();
    setTimeout(() => {
      this.onSearchByColumn('firsttrigger');
    }, 1000);

  }


  setSelectedColumns() {
    const configColumns = this.settingService.getFiltering(this.usePage);
    if (!configColumns || !configColumns.valSearch) {
      this.selectedColumns = this.jsonModel.filter(f => f.Mandatory == true).map(m => {
        return Object.assign({ Key: m.HeadingName, Value: m.ColumnName });
      });
    } else {
      const columnArray = configColumns.valSearch.split(',');
      this.selectedColumns = this.jsonModel.filter(f => columnArray.some(s => s == f.ColumnName)).map(m => {
        return Object.assign({ Key: m.HeadingName, Value: m.ColumnName });
      });
    }
    if (this.selectedColumns) {
      this.tempJsonModel = this.jsonModel.filter(f => this.selectedColumns.some(s => s.Value == f.ColumnName));
    }


    if (this.fieldList) {
      if (!configColumns || !configColumns.valField) {
        this.selectedFields = this.fieldList.filter(f => f.Mandatory == true);
      } else {
        const columnArray = configColumns.valField.split(',');
        this.selectedFields = this.fieldList.filter(f => columnArray.some(s => s == f.ColumnName));
      }
    }


  }

  onSearchByColumn(defStatus: string = 'generated') {

    const data = this.tempJsonModel.filter(f => (f.Val && f.Val != '')
    || (f.Val2 && f.Val2 != '')).map(mp => {
      const val1 = (mp.Val instanceof Array) ? mp.Val.join(',') : mp.Val;
      let val2 = (mp.Val2 instanceof Array) ? mp.Val2.join(',') : mp.Val2;
      const pipe = new DatePipe('en-US');
      if (mp.IsDateTime) {
        if (val1 == '6' && val2 && val2.indexOf(',') > -1) {
          let rangDate = '';
          val2.split(',').forEach(e => {
            if (e) {
              rangDate += pipe.transform(e, 'yyyy-MM-dd HH:mm') + ',';
            }
          });
          val2 = rangDate.slice(0, (rangDate.length - 1));
        } else {
          val2 = pipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
        }
      }
      if (mp.IsCustom) {
        mp.ColumnName = mp.Optional;
      }
      return { ColumnName: mp.ColumnName, Keyword1: val1, Keyword2: val2
        , Enabled: mp.Enabled
        , Optional: mp.IsCustom ? null :  mp.Optional
        , IsDateTime: mp.IsDateTime
        , IsNumber: mp.IsNumber };
    });
    if (data) {
      this.searchQuery = data.filter(f => this.checkQuery(f));
    }

    if (this.fieldList) {
      const fieldData = this.selectedFields.map(mp => {
        return { columnName: mp.ColumnName, headingName: mp.HeadingName };
      });
      if (fieldData) {
        this.fields = fieldData;
      }
    }

    this.sendInfo.next({ status: defStatus });
  }


  checkQuery(obj: any): boolean {
    if (obj.IsDateTime && obj.Keyword1 == 'none') {
      return false;
    }
    return true;
  }

  onChangeTab(e: any) {
    this.tabs.map(m => {
      if (m.title === e.tabTitle) {
        m.active = true;
      } else {
        m.active = false;
      }
    });
  }

  onSaveSettings() {
    const setFilter = (!this.selectedColumns ? '' : this.selectedColumns.map(m => m.Value).join(','));
    const setField = (!this.selectedFields ? '' : this.selectedFields.map(m => m.ColumnName).join(','));
    this.settingService.setFiltering(Object.assign({ valSearch: setFilter, valField: setField }), this.usePage);
    this.setSelectedColumns();
    this.popup.showMsgSuccess('Success Message', 'The Settings Saved');
    this.tabs[0].active = true;
    this.tabs[1].active = false;
  }

  onReset() {
    const hasValues = this.tempJsonModel.filter(f => f.Val || f.Val2);
    if (hasValues) {
      hasValues.forEach(e => {
        e.Val = null;
        e.Val2 = null;
        const def = this.jsonModel.find(j => j.ColumnName == e.ColumnName && j.Default);
        if (def) {
          e.Val = def.Default;
        }
      });
    }
  }


}

import { NbMenuItem } from '@nebular/theme';

export const TRACKING_MENU_ITEMS: NbMenuItem[] = [
    {
        title: 'Overview',
        link: '/modules/tracking'
    },
    {
        title: 'FUNCTIONS',
        group: true
    },
    {
        title: 'Tracking',
        children: [
            {
                title: 'Receipt',
                link: '/modules/tracking/receipt'
            },
            {
                title: 'Shipping',
                link: '/modules/tracking/shipping'
            }
        ]
    }

];

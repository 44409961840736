import { Component, OnInit } from '@angular/core';
import { IdentityService } from '@app/core/authentication/identity.service';
import { NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@app/shared/services/user.service';
import { IdentitySignalrService } from './identity-signalr.service';
import { OutboundSignalrService } from '@app/shared/services/outbound-signalr.service';
import { LoggingSignalrService } from '../logging-signalr.service';
import { document } from 'ngx-bootstrap';

@Component({
  selector: 'app-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss']
})
export class IdentityComponent implements OnInit {

  public user: any;
  public authenticated = false;
  public authMenuItems = [
    { title: 'Change Project/Customer' },
    { title: 'Change Password' },
    { title: 'Logout' }
  ];
  text: string;

  constructor(
    private identityService: IdentityService,
    private userService: UserService,
    private nbMenuService: NbMenuService,
    private identitySignalrService: IdentitySignalrService,
    private loggingSignalrService: LoggingSignalrService
  ) { }

  ngOnInit() {

    this.authenticated = this.identityService.isAuthorized;
    if (this.authenticated) {
      this.user = this.identityService.userData;
    }

    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'auth-context-menu'),
        map(({ item: { title } }) => title),
      ).subscribe(title => {
        const _title = title.toUpperCase();
        switch (_title) {
          case 'LOGOUT':
            this.identitySignalrService.stop();
            // this.outboundSignalrService.stop();
            this.loggingSignalrService.stop();
            this.identityService.Logoff();
            break;
          case 'CHANGE PASSWORD':
            this.identityService.changePassword();
            break;
          case 'CHANGE PROJECT/CUSTOMER':
            this.identityService.SelectCustomer();
            break;
        }
      });
  }

  // checkAllocatePermisison() {
  //   if (this.user) {
  //     this.identityService.checkAllocatePermisison({ Key: this.user.name, ConnectId: '', IsRemove: true })
  //       .subscribe();
  //   }
  // }
}

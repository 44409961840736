import { Injectable } from '@angular/core';
import { StorageService } from '@app/core/storage.service';
import { IdentityService } from '@app/core/authentication/identity.service';
import { UserSettingDetail } from '@app/shared/models/user-setting/user-setting-config';


@Injectable({
  providedIn: 'root'
})
export class UserSettingService {
  user: any;
  constructor(
    private storage: StorageService,
    private identity: IdentityService
  ) {
    this.user = this.identity.getUserEmail();
  }

  public setFiltering(data: any, usePage: any) {
    const settingConfig = this.storage.retrieve('userSetting') || {};
    const noConfig = settingConfig === {};
    const noUserId = !settingConfig[this.user];
    const noFiltering = !(settingConfig[this.user] || {}).filtering;

    if (noConfig || noUserId) {
      settingConfig[this.user] = new UserSettingDetail();
      settingConfig[this.user].filtering[usePage] = data;
    } else if (noFiltering) {
      settingConfig[this.user].filtering = {};
      settingConfig[this.user].filtering[usePage] = data;
    } else {
      settingConfig[this.user].filtering[usePage] = data;
    }
    this.storage.store('userSetting', settingConfig);
  }

  public getFiltering(usePage: any) {
    const settingConfig = this.storage.retrieve('userSetting');
    return (((settingConfig || {})[this.user] || {}).filtering || {})[usePage];
  }

  public setDefaultPageSize(pageSize: any) {
    const settingConfig = this.storage.retrieve('userSetting') || {};
    const noConfig = settingConfig === {};
    const noUserId = !(settingConfig || {})[this.user];

    if (noConfig || noUserId) {
      settingConfig[this.user] = new UserSettingDetail();
      settingConfig[this.user].currentMaxPaging = pageSize;
    } else {
      settingConfig[this.user].currentMaxPaging = pageSize;
    }
    this.storage.store('userSetting', settingConfig);
  }

  public getCurrentMaxPaging() {
    const settingConfig = this.storage.retrieve('userSetting');
    return ((settingConfig || {})[this.user] || {}).currentMaxPaging;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Status } from '@app/shared/models/tracking/status.model';

@Injectable({
  providedIn: 'root'
})
export class UserActivityLogService {

  constructor(private httpClient: HttpClient) { }

  getLogDetailList() {
    return this.httpClient.auth()
      .get(environment.service.core.logging + '/logs/UserActivityLogs');
  }
}

import { Injectable } from '@angular/core';
import { StorageService } from '@app/core/storage.service';
import { ProjectService } from './project.service';

@Injectable()
export class ProjectConfigService {
    constructor(private storage: StorageService,
        private projectService: ProjectService) {

    }

    public checkVersion() {
        const currentConfigs = this.getAllConfigs();
        if (!currentConfigs) {
            this.updateProjectConfig();
        } else {
            try {
                this.projectService.getCheckProjectConfigVersion(this.getVersion())
                    .subscribe((res: any) => {
                        const result = res.Result;
                        if (!JSON.parse(result)) {
                            this.updateProjectConfig();
                        }
                    });
            } catch (error) {
                console.log('get version projectconfig' + error);
            }
        }
    }

    public getAllConfigs() {
        return this.storage.retrieve('projectConfig');
    }

    public getItemConfigs() {
        return this.getAllConfigs().item;
    }

    public getGeneralConfigs() {
        return this.getAllConfigs().general;
    }

    public getInventoryConfigs() {
        return this.getAllConfigs().inventory;
    }

    public getReceiveConfigs() {
        return this.getAllConfigs().receive;
    }

    public getSparefieldConfigs() {
        return this.getAllConfigs().sparefields;
    }

    public getDefaultItem() {
        return {
            item: {
                maxControlLevel: 1
                , maxSerialNumber: 1
                , usedBestBeforeDateControl: false
                , usedExpireDateControl: true
                , usedItemCode: true
                , usedJanCode: false
                , usedManufacturingDateControl: false
                , usedScanCode: false
                , usedSerialNumber: true
            }
        };
    }

    public getDefaultGeneral() {
        return {
            business: {
                isB2B: true
                , isB2C: false
                , isC2C: false
            }
        };
    }

    public getDefaultInventory() {
        return {
            allocate: {
                isFEFO: true
                , isFIFO: true
                , isLIFO: true
            }, isVIPCustomer: false
        };
    }

    public getDefaultReceive() {

    }

    public getVersion(): number {
        let v = 1;
        const configs = this.getAllConfigs();
        configs ? v = configs.version : v = 1;
        return v;
    }

    updateProjectConfig() {
        try {
            this.projectService.getProjectConfig().subscribe((res2: any) => {
                if (res2.Result) {
                    const obj = res2.Result ? res2.Result.configs : '{}';
                    this.storage.store('projectConfig', JSON.parse(obj));
                }
            });
        } catch (error) {
            console.log('get projectconfig: ' + error);
        }
    }

}

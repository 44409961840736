import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { NbMenuItem, NbMediaBreakpoint } from '@nebular/theme';
import { Title } from '@angular/platform-browser';

import {
    NbMediaBreakpointsService,
    NbSidebarService,
    NbThemeService
} from '@nebular/theme';
import { MenuService } from '@app/core/shell/menu/menu.service';
import { IdentityService } from '@app/core/authentication/identity.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { NbMenuService, NbMenuBag } from '@nebular/theme/components/menu/menu.service';
import { ProjectService } from '@app/shared/services/project.service';
import { takeWhile, withLatestFrom, delay } from 'rxjs/operators';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { AllocateService } from '@app/modules/outbound/allocate/allocate.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {

    public menuItems: NbMenuItem[];
    public user: any;
    public authenticated = false;
    public isLoading: true;

    public projectConfig: EventEmitter<any> = new EventEmitter();
    public signalREvent: EventEmitter<any> = new EventEmitter();

    navigationSubscription;

    private alive = true;
    private signalRSubscrib: Subscription;

    constructor(
        protected menuService: NbMenuService,
        protected themeService: NbThemeService,
        protected bpService: NbMediaBreakpointsService,
        protected sidebarService: NbSidebarService,
        private moduleMenuService: MenuService,
        private titleService: Title,
        private identityService: IdentityService,
        private projectConfigService: ProjectConfigService,
        private router: Router,
    ) {
        // this.moduleMenuService.getMenuShowdata();

        this.navigationSubscription = this.router.events.subscribe((e: any) => {

            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {
                this.customInit();
            }
        });

        const isBp = this.bpService.getByName('is');
        this.menuService.onItemSelect()
            .pipe(
                takeWhile(() => this.alive),
                withLatestFrom(this.themeService.onMediaQueryChange()),
                delay(20),
            )
            .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
                if (item.item.parent && (!item.item.parent && !item.item.children)) {
                    this.projectConfigService.checkVersion();
                }
                if (bpTo.width <= isBp.width) {
                    this.sidebarService.collapse('shell-menuItems');
                }
            });
    }

    ngOnInit() {
        this.authenticated = this.identityService.isAuthorized;
        if (this.authenticated) {
            this.user = this.identityService.userData;
        }
        this.projectConfigService.checkVersion();
    }

    customInit() {
        if (this.menuItems == undefined) {
            this.menuItems = this.moduleMenuService.getMenuItem();
        }
        const isAdmin = this.identityService.isAdmin();
        if (!isAdmin) {
            this.menuItems = this.menuItems.filter(f => f.data != 'adminOnly');
        }
        // const inspectby = this.projectConfigService.getInventoryConfigs().inspect.inspectby;
        // if (inspectby) {
        //     const inspectMenu = this.menuItems.filter(f => f.title == 'Inspection')[0];
        //     if (inspectMenu) {
        //         if (inspectby.itemGroup === false) {
        //             if (inspectMenu.children.findIndex(f => f.title == 'Shipping Inspection') !== -1) {
        //                 inspectMenu.children.splice(inspectMenu.children.findIndex(f => f.title == 'Shipping Inspection'), 1);
        //             }
        //         }
        //         if (inspectby.itemCode === false) {
        //             if (inspectMenu.children.findIndex(f => f.title == 'Shipping Inspection By Item Code') !== -1) {
        //                 inspectMenu.children.splice(inspectMenu.children.findIndex(f => f.title == 'Shipping Inspection By Item Code'), 1);
        //             }
        //         }
        //         if (inspectby.pickingList === false) {
        //             if (inspectMenu.children.findIndex(f => f.title == 'Shipping Inspection By Picking List') !== -1) {
        //                 inspectMenu.children.splice(inspectMenu.children.findIndex(f => f.title == 'Shipping Inspection By Picking List'), 1);
        //             }
        //         }
        //     }

        // }
    }



    ngOnDestroy() {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
        if (this.signalRSubscrib) {
            this.signalRSubscrib.unsubscribe();
        }
        this.alive = false;
        this.menuService.collapseAll();
    }

    get pageTitle(): string {
        return this.titleService.getTitle();
    }

}

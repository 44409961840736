import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCalendarKitModule
} from '@nebular/theme';


import { ShellComponent } from '@app/core/shell/shell.component';
import { RouteReusableStrategy } from '@app/core/route-reusable-strategy';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { AuthenticationGuard } from '@app/core/authentication/authentication.guard';
import { I18nService } from '@app/core/i18n.service';
import { HttpService } from '@app/core/http/http.service';
import { HttpCacheService } from '@app/core/http/http-cache.service';
import { ApiPrefixInterceptor } from '@app/core/http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from '@app/core/http/error-handler.interceptor';
import { CacheInterceptor } from '@app/core/http/cache.interceptor';
import { HeaderComponent } from '@app/core/shell/header/header.component';
import { BreadcrumbComponent } from '@app/core/shell/breadcrumb/breadcrumb.component';
import { IdentityComponent } from '@app/core/authentication/identity.component';
import { AuthInterceptor } from '@app/core/http/auth-interceptor';
import { LanguageComponent } from './shell/language/language.component';
import { LoaderInterceptor } from '@app/core/http/loader.interceptor';
import { SharedModule } from '@app/shared';
import { ProjectSwitcherComponent } from './shell/project-switcher/project-switcher.component';
import { CORPORATE_THEME } from '@theme/theme.corporate';
import { DisableApiResponseInterceptor } from './http/disable-api-response-interceptor';
import { EncodeInterceptor } from './http/encode-interceptor';
import { SignalrService } from '@app/shared/services/signalr-service';
import { NotificationComponent } from './shell/notification/notification.component';

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  TranslateModule,
  RouterModule
];

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCalendarKitModule
];

const COMPONENTS = [
  HeaderComponent,
  LanguageComponent,
  BreadcrumbComponent,
  ShellComponent,
  IdentityComponent,
  LanguageComponent
];

const NB_THEME_PROVIDERS = [
  NbThemeModule.forRoot(
    {
      name: 'corporate'
    },
    [ CORPORATE_THEME ]
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers
];

@NgModule({
  imports: [
    NgbModule,
    ...BASE_MODULES,
    ...NB_MODULES,
    SharedModule
  ],
  exports: [
    ...BASE_MODULES,
    ...NB_MODULES,
    ...COMPONENTS
  ],
  declarations: [
    COMPONENTS,
    IdentityComponent,
    ProjectSwitcherComponent,
    NotificationComponent
  ],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    SignalrService,
    I18nService,
    HttpCacheService,
    LoaderInterceptor,
    ApiPrefixInterceptor,
    AuthInterceptor,
    EncodeInterceptor,
    ErrorHandlerInterceptor,
    DisableApiResponseInterceptor,
    CacheInterceptor,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    },
    NB_THEME_PROVIDERS
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_THEME_PROVIDERS,
      ],
    };
  }
}

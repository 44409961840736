import { NbMenuItem } from '@nebular/theme';

export const ADMIN_DEFINITION_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Overview',
    link: '/modules/definition'
  },
  {
    title: 'FUNCTIONS',
    group: true
  },
  {
    title: 'Definition Config',
    link: '/modules/definition/roleconfig'
  },
  {
    title: 'Customer Definition',
    children: [
      {
        title: 'Import End Customer Definition',
        link: '/modules/definition/customer/import',
      }
    ]
  }

];

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  constructor(private http: HttpClient) { }

  env = environment;
  getWarehouseList() {
    return this.http.auth().get(environment.service.wms.location + '/warehouses');
  }

  getWarehouseByID(url: string, body?: any) {
    return this.http.auth().get(environment.service.wms.location + url, body);
  }

  getWarehouseDropdownList() {
    return this.http.auth().get(environment.service.wms.location + '/warehouses/dropdown');
  }

  postCreateWarehouse(body?: any) {
    return this.http.auth().post(environment.service.wms.location + '/warehouses', body)
      ;
  }

  putEditWarehouse(body?: any) {
    return this.http.auth().put(environment.service.wms.location + '/warehouses/', body)
      ;
  }
}

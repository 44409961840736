import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PopupModalComponent } from '../components/popup/popup-modal/popup-modal.component';

@Injectable()
export class AddressService {
  predata;
  filterAddress;
  result;
  constructor(
    private http: HttpClient,
    private popupMessageGrowl: PopupModalComponent
  ) {
    this.getLocationDataEn().subscribe(
      res => {
        localStorage.setItem('addressEn', JSON.stringify(this.preprocess(res)));
      }
    );
    this.getLocationData().subscribe(
      res => {
        this.predata = this.preprocess(res);
        localStorage.setItem('addressDef', JSON.stringify(this.predata));
      }
    );
  }

  getLocationData() {
    return this.http.disableApiPrefix().get('./assets/json/address.json');
  }

  getLocationDataEn() {
    return this.http.disableApiPrefix().get('./assets/json/address_En.json');
  }

  callByLanguage(query: any) {
    if (query.match((/[A-Z]/ig))) {
      const matchEn = query.match((/[A-Z]/ig)).length;
      const notEn = query.length - matchEn;
      if (matchEn > notEn) {
        this.predata = JSON.parse(localStorage.getItem('addressEn'));
      }
    } else {
      this.predata = JSON.parse(localStorage.getItem('addressDef'));
    }
  }

  searchProvince(event: any, lang: string = 'th') {
    this.callByLanguage(event.query);
    if (lang === 'en') {
      this.predata = JSON.parse(localStorage.getItem('addressEn'));
    }
    const query = this.myTrim(event.query).toLowerCase();
    const elength = query.length;
    this.filterAddress = [];

    const txtEqual = this.predata.filter(x => this.myTrim(x.province).substr(0, elength).toLowerCase() === query);
    if (txtEqual.length !== 0) {
      this.filterAddress = txtEqual;
    } else {
      this.filterAddress = this.predata.filter(x => this.myTrim(x.province).toLowerCase().includes(query));
    }
    this.filterAddress = this.filterAddress.sort((a, b) => {
      return (this.myTrim(a.province).length - elength > this.myTrim(b.province).length - elength) ? 1 : ((this.myTrim(a.province).length - elength < this.myTrim(b.province).length - elength) ? -1 : 0);
    });
  }

  searchAmphoe(event: any, lang: string = 'th') {
    this.callByLanguage(event.query);
    if (lang === 'en') {
      this.predata = JSON.parse(localStorage.getItem('addressEn'));
    }
    const query = this.myTrim(event.query).toLowerCase();
    const elength = query.length;
    this.filterAddress = [];

    const txtEqual = this.predata.filter(x => this.myTrim(x.amphoe).substr(0, elength).toLowerCase() === query);
    if (txtEqual.length !== 0) {
      this.filterAddress = txtEqual;
    } else {
      this.filterAddress = this.predata.filter(x => this.myTrim(x.amphoe).toLowerCase().includes(query));
    }
    this.filterAddress = this.filterAddress.sort((a, b) => {
      return (this.myTrim(a.amphoe).length - elength > this.myTrim(b.amphoe).length - elength) ? 1 : ((this.myTrim(a.amphoe).length - elength < this.myTrim(b.amphoe).length - elength) ? -1 : 0);
    });
  }

  searchDistrict(event: any, lang: string = 'th') {
    this.callByLanguage(event.query);
    if (lang === 'en') {
      this.predata = JSON.parse(localStorage.getItem('addressEn'));
    }
    const query = this.myTrim(event.query).toLowerCase();
    const elength = query.length;
    this.filterAddress = [];

    const txtEqual = this.predata.filter(x => this.myTrim(x.district).substr(0, elength).toLowerCase() === query);
    if (txtEqual.length !== 0) {
      this.filterAddress = txtEqual;
    } else {
      this.filterAddress = this.predata.filter(x => this.myTrim(x.district).toLowerCase().includes(query));
    }
    this.filterAddress = this.filterAddress.sort((a, b) => {
      return (this.myTrim(a.district).length - elength > this.myTrim(b.district).length - elength) ? 1 : ((this.myTrim(a.district).length - elength < this.myTrim(b.district).length - elength) ? -1 : 0);
    });
  }

  searchZipcode(event: any, lang: string = 'th') {
    this.callByLanguage(event.query);
    if (lang === 'en') {
      this.predata = JSON.parse(localStorage.getItem('addressEn'));
    }
    this.filterAddress = [];
    const elength = event.query.length;
    const txtEqual = this.predata.filter(x => x.zipcode.substr(0, elength) === event.query);
    let count = txtEqual.length;
    count = count > 50 ? 50 : count;
    for (let i = 0; i < count; i++) {
      this.filterAddress.push(txtEqual[i]);
    }
  }

  searchById(toEN: boolean, districtId: number, result: any): any {
    let tempPreData = JSON.parse(localStorage.getItem('addressDef'));
    if (toEN) {
      tempPreData = JSON.parse(localStorage.getItem('addressEn'));
    }
    const data = tempPreData.find(x => x.SubCityIDSys == districtId);
    if (data !== undefined) {
      if (toEN) {
        result.addressEn3 = data.district;
        result.addressEn4 = data.amphoe;
        result.addressEn5 = data.province;
        result.addressEn6 = data.zipcode;
      } else {
        result.address3 = data.district;
        result.address4 = data.amphoe;
        result.address5 = data.province;
        result.address6 = data.zipcode;
      }
    }
  }

  myTrim(x: any) {
    return x.replace(/\s/g, '');
  }

  selectAddress(event: any, result: any) {
    setTimeout(() => {
      result.address3 = event.district;
      result.address4 = event.amphoe;
      result.address5 = event.province;
      result.address6 = event.zipcode;
      this.searchById(true, event.SubCityIDSys, result);
    }, 10);

    // result.Address = event.district
    //   + ' ' + event.amphoe
    //   + ' ' + event.province
    //   + ' ' + event.zipcode;
  }

  selectAddressEn(event: any, result: any) {
    setTimeout(() => {
      result.addressEn3 = event.district;
      result.addressEn4 = event.amphoe;
      result.addressEn5 = event.province;
      result.addressEn6 = event.zipcode;
      this.searchById(false, event.SubCityIDSys, result);
    }, 10);

    // result.address = event.district
    //   + ' ' + event.amphoe
    //   + ' ' + event.province
    //   + ' ' + event.zipcode;
  }

  preprocess(data: any) {
    const expanded = [];
    let lookup = [], words = [], t;

    if (data.lookup && data.words) {
      lookup = data.lookup.split('|');
      words = data.words.split('|');
      data = data.data;
    }
    const headKeys = ['!', '@', '#', '$', '%', '^', '&', '*'];
    const keys = [];
    headKeys.forEach(key => {
      for (let i = 65; i <= 122; i++) {
        keys.push(key + String.fromCharCode(i));
        if (i === 90) {
          i = 96;
          continue;
        }
      }
    });

    t = function (text: string) {
      function repl(m: any) {
        const ch = keys.indexOf(m);
        return words[ch];
      }
      if (!isNaN(Number(text))) {
        text = lookup[Number(text)];
      }
      do { text = text.replace(/[!@#$%^&*][A-Z]/ig, repl); } while (text.match(/[!@#$%^&*][A-Z]/ig));
      return text;
    };
    data.map(function (provinces: any) {
      const i = 1;
      provinces[i].map(function (amphoes: any) {
        amphoes[i].map(function (districts: any) {
          districts[i] = districts[i] instanceof Array ? districts[i] : [districts[i]];
          districts[i].map(function (zipcode: any) {
            const entry = {
              district: t(districts[0]),
              amphoe: t(amphoes[0]),
              province: t(provinces[0]),
              zipcode: zipcode,
              SubCityIDSys: districts[2]
            };
            expanded.push(entry);
          });
        });
      });
    });
    return expanded;
  }

  checkInputAddress(data: any) {
    let pass = true;
    if (
      !data.address1 || data.address1 === null &&
      !data.address3 || data.address3 === null &&
      !data.address4 || data.address4 === null &&
      !data.address5 || data.address5 === null &&
      !data.address6 || data.address6 === null &&
      !data.countryCode || data.countryCode === null &&
      !data.countryName || data.countryName === null
    ) {
      this.popupMessageGrowl.showMsgWarning('', 'Please Insert Address');
      pass = false;
    } else
      if (
        data.address1.replace(/\s/g, '') === '' ||
        data.address3.replace(/\s/g, '') === '' ||
        data.address4.replace(/\s/g, '') === '' ||
        data.address5.replace(/\s/g, '') === '' ||
        data.address6.replace(/\s/g, '') === ''
      ) {
        this.popupMessageGrowl.showMsgWarning('', 'Please Insert Address');
        pass = false;
      }
    if (
      !data.addressEn1 || data.addressEn1 === null &&
      !data.addressEn3 || data.addressEn3 === null &&
      !data.addressEn4 || data.addressEn4 === null &&
      !data.addressEn5 || data.addressEn5 === null &&
      !data.addressEn6 || data.addressEn6 === null
    ) {
      this.popupMessageGrowl.showMsgWarning('', 'Please Insert English Address');
      pass = false;
    } else
      if (
        data.addressEn1.replace(/\s/g, '') === '' ||
        data.addressEn3.replace(/\s/g, '') === '' ||
        data.addressEn4.replace(/\s/g, '') === '' ||
        data.addressEn5.replace(/\s/g, '') === '' ||
        data.addressEn6.replace(/\s/g, '') === ''
      ) {
        this.popupMessageGrowl.showMsgWarning('', 'Please Insert English Address');
        pass = false;
      }
    if (data.address6 !== data.addressEn6) {
      this.popupMessageGrowl.showMsgWarning('', 'ZIP Code Thai Address and English Address not Match');
      pass = false;
    }

    return pass;
  }
}

export const BOOTSTRAP_SAMPLE_FORM_LAYOUT = {


    "itemCodeGroup": {
        grid: {
            control: "ui-g"
        }
    },
    "itemCodeSwitch": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },
    "itemCodeSelect": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },
    "itemCodeConditionSelect": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },
    "itemCode": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },


    "serialNumber1Group": {
        grid: {
            control: "ui-g"
        }
    },
    "serialNumber1Switch": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },
    "serialNumber1ConditionSelect": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },
    "serialNumber1": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },


    "serialNumber2Group": {
        grid: {
            control: "ui-g"
        }
    },
    "serialNumber2Switch": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-2",
            container: "ui-g"
        }
    },
    "serialNumber2ConditionSelect": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-2",
            container: "ui-g"
        }
    },
    "serialNumber2": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-2",
            container: "ui-g"
        }
    },


    "serialNumber3Group": {
        grid: {
            control: "ui-g"
        }
    },
    "serialNumber3Switch": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-3",
            container: "ui-g"
        }
    },
    "serialNumber3ConditionSelect": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-3",
            container: "ui-g"
        }
    },
    "serialNumber3": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-3",
            container: "ui-g"
        }
    },


    "receiveDateGroup": {
        grid: {
            control: "ui-g"
        }
    },
    "receiveDateSwitch": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },
    "receiveDateConditionSelect": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },
    "startReceiveDate": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },
    "endReceiveDate": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },






    "stay": {
        grid: {
            control: "ui-g"
        }
    },

    "arrivalDate": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g-4"
        }
    },

    "departureDate": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g-4"
        }
    },

    "room": {
        grid: {
            control: "ui-g"
        }
    },

    "roomSize": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g-4",
        }
    },

    "roomQuantity": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g-2",
        }
    },

    "roomColor": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g",
            container: "ui-g-7"
        }
    },

    "firstName": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g",
            container: "ui-g-7"
        }
    },

    "lastName": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g",
            container: "ui-g-7"
        }
    },

    "email": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g",
            container: "ui-g-7"
        }
    },

    "phone": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g",
            container: "ui-g-7"
        }
    },

    "addressStreet": {
        grid: {
            control: "ui-g"
        }
    },

    "streetName": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g-7"
        }
    },

    "streetNumber": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g-2",
        }
    },

    "addressLocation": {
        grid: {
            control: "ui-g"
        }
    },

    "zipCode": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g-2"
        }
    },

    "state": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g-4",
        }
    },

    "city": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g-5",
        }
    },

    "extras": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "ui-g",
            control: "ui-g"
        }
    },

    "extraBreakfast": {
        element: {
            label: "ui-widget",
        },
        grid: {
            host: "ui-g-3"
        }
    },

    "extraTV": {
        element: {
            label: "ui-widget",
        },
        grid: {
            host: "ui-g-3"
        }
    },

    "extraWiFi": {
        element: {
            label: "ui-widget",
        },
        grid: {
            host: "ui-g-3"
        }
    },

    "extraParking": {
        element: {
            label: "ui-widget",
        },
        grid: {
            host: "ui-g-3"
        }
    },

    "payment": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "ui-g",
            control: "ui-g",
            option: "ui-g-3"
        }
    },

    "arrivalTime": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g",
            container: "ui-g-4"
        }
    },

    "note": {
        element: {
            label: "ui-widget"
        },
        grid: {
            host: "ui-g",
            container: "ui-g-8"
        }
    },

    "reminder": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },

    "newsletter": {
        element: {
            label: "ui-widget"
        },
        grid: {
            label: "order-1",
            container: "ui-g"
        }
    },

    "feedback": {
        element: {
            label: "ui-widget"
        },
        grid: {
            container: "ui-g"
        }
    },

    "confirm": {
        element: {
            label: "ui-widget"
        }
    }
};
import { environment } from '@env/environment';

export class ProjectConfigSet {
  public static setProjectConfigItemToColumn(projectConfig: any, cols: any[]): any[] {
    if (projectConfig.item && cols) {
      const currentLevel = projectConfig.item.maxControlLevel;
      for (let i = (currentLevel + 1); i <= environment.projectConfig.maxControllevel; i++) {
        const txtControllevel = 'controllevel' + i;
        let index = cols.findIndex(f => (f.columnName && f.columnName.toLowerCase() == txtControllevel));
        index = (index > -1) ? index : cols.findIndex(f => f.ColumnName && f.ColumnName.toLowerCase() == txtControllevel);
        if (index > -1) { cols.splice(index, 1); }
      }

      const indexSerial = cols.findIndex(f => (f.columnName && f.columnName.toLowerCase() == 'serialnumber1'));
      const currentSerialLevel = projectConfig.item.usedSerialNumber ? projectConfig.item.maxSerialNumber : 3;
      if (indexSerial > -1) {
         cols.splice(indexSerial + currentSerialLevel, 3 - currentSerialLevel);
         }

         if (!projectConfig.item.usedSerialNumber) {
          const indexSerialQty = cols.findIndex(f => f.columnName && f.columnName.toLowerCase() == 'serialqty');
          if (indexSerialQty > -1) { cols.splice(indexSerialQty, 1); }
        }

      if (!projectConfig.item.usedExpireDateControl) {
        const indexExpire = cols.findIndex(f => f.columnName && f.columnName.toLowerCase() == 'expiredate');
        if (indexExpire > -1) { cols.splice(indexExpire, 1); }
      }
      if (!projectConfig.item.usedBestBeforeDateControl) {
        const indexBest = cols.findIndex(f => f.columnName && f.columnName.toLowerCase() == 'bestbeforedate');
        if (indexBest > -1) { cols.splice(indexBest, 1); }
      }
      if (!projectConfig.item.usedManufacturingDateControl) {
        const indexManu = cols.findIndex(f => f.columnName && f.columnName.toLowerCase() == 'manufacturingdate');
        if (indexManu > -1) { cols.splice(indexManu, 1); }
      }
      if (!projectConfig.item.usedScanCode) {
        const indexScan = cols.findIndex(f => f.columnName && f.columnName.toLowerCase() == 'scancode');
        if (indexScan > -1) { cols.splice(indexScan, 1); }
      }
      if (!projectConfig.item.usedJanCode) {
        const indexJan = cols.findIndex(f => f.columnName && (f.columnName.toLowerCase() == 'jan'
          || f.columnName.toLowerCase() == 'jancode'));
        if (indexJan > -1) { cols.splice(indexJan, 1); }
      }


      const indexSerialB = cols.findIndex(f => (f.ColumnName && f.ColumnName.toLowerCase() == 'serialnumber1'));
      const currentSerialLevelB = projectConfig.item.usedSerialNumber ? projectConfig.item.maxSerialNumber : 3;
      if (indexSerialB > -1) { cols.splice(indexSerialB + currentSerialLevelB, 3 - currentSerialLevelB); }

      if (!projectConfig.item.usedSerialGroup) {
        const indexSerialGroup = cols.findIndex(f => f.ColumnName && f.ColumnName.toLowerCase() == 'serialGroup');
        if (indexSerialGroup > -1) { cols.splice(indexSerialGroup, 1); }
      }

      if (!projectConfig.item.usedSerialNumber) {
        const indexSerialQtyB = cols.findIndex(f => f.ColumnName && f.ColumnName.toLowerCase() == 'serialqty');
        if (indexSerialQtyB > -1) { cols.splice(indexSerialQtyB, 1); }
      }


      if (!projectConfig.item.usedExpireDateControl) {
        const indexExpire = cols.findIndex(f => f.ColumnName && f.ColumnName.toLowerCase() == 'expiredate');
        if (indexExpire > -1) { cols.splice(indexExpire, 1); }
      }
      if (!projectConfig.item.usedBestBeforeDateControl) {
        const indexBest = cols.findIndex(f => f.ColumnName && f.ColumnName.toLowerCase() == 'bestbeforedate');
        if (indexBest > -1) { cols.splice(indexBest, 1); }
      }
      if (!projectConfig.item.usedManufacturingDateControl) {
        const indexManu = cols.findIndex(f => f.ColumnName && f.ColumnName.toLowerCase() == 'manufacturingdate');
        if (indexManu > -1) { cols.splice(indexManu, 1); }
      }
      if (!projectConfig.item.usedScanCode) {
        const indexScan = cols.findIndex(f => f.ColumnName && f.ColumnName.toLowerCase() == 'scancode');
        if (indexScan > -1) { cols.splice(indexScan, 1); }
      }
      if (!projectConfig.item.usedJanCode) {
        const indexJan = cols.findIndex(f => f.ColumnName && (f.ColumnName.toLowerCase() == 'jan'
          || f.ColumnName.toLowerCase() == 'jancode'));
        if (indexJan > -1) { cols.splice(indexJan, 1); }
      }
    }
    return cols;
  }
}

export enum OrderStatus {
    _New = 1,
    _Cancelled = 2,
    _Confirmed = 3,
    _Allocated = 4,
    _Inspecting = 5,
    _Inspected = 6,
    _Shipping = 7,
    _Shipped = 8
}

export enum InputOrderType {
    _Input = 1,
    _Import = 2,
    _Interface = 3
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiPrefixInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = this.extractUrl(request.url);
    // console.log('ApiPrefixInterceptor => ' + requestUrl);
    request = request.clone({ url: requestUrl });
    return next.handle(request);
  }

  private extractUrl(requestUrl: string): string {

    const urlSplit = requestUrl.split('/');
    let url = urlSplit[0] + '//';
    url += urlSplit[2] + '/';
    // console.log(urlSplit);
    // แก้ปัญหาชั่วคราว Url Wim ตัวเก่า
    if (urlSplit[3] !== undefined) {
      if (!urlSplit[3].endsWith('-backend')) {
        url += 'api/';
      }
    }

    for (let i = 3; i < urlSplit.length; i++) {
      url += urlSplit[i] + '/';
    }
    url = url.substring(0, url.length - 1);
    return url;
  }


}

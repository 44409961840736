import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, ExtraOptions } from '@angular/router';
import { PageNotFoundComponent } from '@app/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '**', component: PageNotFoundComponent, pathMatch: 'full' }
];

const config: ExtraOptions = {
  useHash: false,
  // preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [RouterModule.forRoot(routes
    , config
  )],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }

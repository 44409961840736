import { NbMenuItem } from '@nebular/theme';

export const PROJECT_CONFIG_ADMIN_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Project Information',
    link: '/modules/admin/projectconfig'
  },
  {
    title: 'CONFIGURATIONS',
    group: true
  },
  {
    title: 'Main',
    link: '/modules/admin/projectconfig/main'
  },
  {
    title: 'Receiving',
    link: '/modules/admin/projectconfig/receiving'
  },
  {
    title: 'Ordering',
    link: '/modules/admin/projectconfig/ordering'
  },
  {
    title: 'Label display',
    link: '/modules/admin/projectconfig/labeldisplay'
  },
  {
    title: 'Spare Field',
    link: '/modules/admin/projectconfig/sparefield'
  },
  {
    title: 'Menu Manage',
    link: '/modules/admin/projectconfig/menumanage'
  },
];

import { Component, OnInit } from '@angular/core';
import { ConfirmationService, Message } from 'primeng/api';
import { Observable, Observer } from 'rxjs';
import { MessageService } from 'primeng/components/common/messageservice';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-popup-modal',
    templateUrl: './popup-modal.component.html',
    styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent implements OnInit {

    msgs: Message[] = [];
    apiErrorMsgs: Message[] = [];
    closeResult: string;

    dumpConfirm: ConfirmModalComponent;

    constructor(private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private modalService: NgbModal) { }

    ngOnInit() {


    }


    confirmSweet(msg: string, title: string = 'Confirm Message', isTopLeft: boolean = false, showType: string = 'warning') {
        return Swal({
            title: title,
            text: `Message ${msg}`,
            type: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes`,
            focusConfirm: true,
            customClass: 'index',
            position: isTopLeft ? 'top-right' : 'center'
        });
    }


    confirmNew(msg: string = 'Confirm Message ?') {
        const modalRef = this.modalService.open(ConfirmModalComponent, {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'modal-holder',
            backdropClass: 'modal-backdrop',
            centered: true,
        });
        this.dumpConfirm = modalRef.componentInstance;
        this.dumpConfirm.message = msg;
        return modalRef.result;
    }

    confirmWithBtnNew(msg: string = 'Confirm Message ?', buttons: any) {
        const modalRef = this.modalService.open(ConfirmModalComponent, {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'modal-holder',
            centered: true
        });
        this.dumpConfirm = modalRef.componentInstance;
        this.dumpConfirm.message = msg;
        this.dumpConfirm.buttons = buttons;
        return modalRef.result;
    }

    confirmWithCheckboxNew(msg: string = 'Confirm Message ?', checkboxGroup: any) {
        const modalRef = this.modalService.open(ConfirmModalComponent, {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'modal-holder',
            centered: true
        });
        this.dumpConfirm = modalRef.componentInstance;
        this.dumpConfirm.message = msg;
        this.dumpConfirm.checkboxGroup = checkboxGroup;
        return modalRef.result;
    }

    confirm(msg: string = 'Confirm Message ?'): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.confirmationService.confirm({
                message: msg,
                accept: () => {
                    observer.next(true);
                    observer.complete();
                },
                reject: () => {
                    observer.next(false);
                    observer.complete();
                }
            });
        });
    }

    showMsgSuccess(title: string = 'Successfully', detail: string = '') {
        this.messageService.add({ severity: 'success', summary: title, detail: detail });
    }


    showMsgWarning(title: string = 'Warning', detail: string = '') {
        this.messageService.add({ severity: 'warn', summary: title, detail: detail });
    }

    showMsgError(title: string = 'Error', detail: string = '') {
        this.clear();
        this.messageService.add({ severity: 'error', summary: title, detail: detail });
    }

    showError(message: string = 'Error') {
        const modalRef = this.modalService.open(ErrorModalComponent, { windowClass: 'modal-holder', centered: true }).componentInstance;
        modalRef.message = message;
    }

    showMsgInfo(title: string = 'Information', detail: string = '') {
        this.messageService.add({ severity: 'info', summary: title, detail: detail });
    }

    addMultiple() {
        this.messageService.addAll([{ severity: 'success', summary: 'Service Message', detail: 'Via MessageService' },
        { severity: 'info', summary: 'Info Message', detail: 'Via MessageService' }]);
    }

    clear() {
        this.messageService.clear();
    }

}

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SparefieldService {

  constructor(private http: HttpClient) { }

  getSpareField(): Observable<any> {
    return this.http.get(environment.service.wimWms + '/SpareField');
  }

  getSpareFieldByTableName(tableName: string): Observable<any> {
    return this.http.get(environment.service.wimWms + '/SpareField/table/' + tableName);
  }

  getTableControl(): Observable<any> {
    return this.http.get(environment.service.wimMaster + '/TableControl');
  }

  getSpareFieldByID(id: string) {
    return this.http.get(environment.service.wimWms + '/SpareField/' + id);
  }

  getSpareFieldByProjectId() {
    const user = JSON.parse(localStorage.getItem('userData'));
    let projectId = (+user.projectId);
    if (user === undefined) {
      projectId = 0;
    }
    return this.http.get(environment.service.wimWms + '/SpareField/Project/' + projectId);
  }

  getSpareFields(group: string) {
    return this.http
      .auth()
      .get(environment.service.wms.project + '/sparefields/' + group);
  }
  getSpareFieldsOriginal(group: string) {
    return this.http
      .auth()
      .get(environment.service.wms.project + '/sparefields/original/' + group);
  }

  getSpareFieldGroups() {
    return this.http
      .auth()
      .get(environment.service.wms.project + '/sparefields/SpareFieldGroups');
  }

  getSpareFieldShares(id: number) {
    return this.http
      .auth()
      .get(environment.service.wms.project + '/sparefields/GetSpareFieldShares/' + id);
  }

  getForeignKeyByTable(dbName: string, tableName: string) {
    return this.http
      .auth()
      .get(environment.service.wms.project + '/sparefields/GetForeignKeyByTable/' + dbName + '/' + tableName);
  }

  saveSpareFields(spareFieldGroupSelected: string, body: any) {
    return this.http
      .auth()
      .post(environment.service.wms.project + '/sparefields/' + spareFieldGroupSelected, body);
  }

  saveSpareFieldShare(body: any) {
    return this.http
      .auth()
      .post(environment.service.wms.project + '/sparefields/share' , body);
  }

  createSpareField(body: any) {
    return this.http.post(environment.service.wimWms + '/SpareField', body);
  }

  updateSpareField(body: any) {
    const user = JSON.parse(localStorage.getItem('userData'));
    let projectId = (+user.projectId);
    if (user === undefined) {
      projectId = 0;
    }
    return this.http.put(environment.service.wimWms + '/SpareField/' + projectId, body);
  }

  deleteSpareField(id: number) {
    return this.http.delete(environment.service.wimWms + '/SpareField/' + id);
  }

}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// `.env.ts` is generated by the `npm run env` command
import env from '@env/.env';

const client_id = location.hostname + '-yutsys-wms-frontend';

const host = {
  local: 'http://localhost',
  // public: 'http://192.168.110.2'
  public: 'http://192.168.110.1'
};

// const client = {
//   id: {
//     local: 'yutsys-wms-frontend-dev',
//     test: 'yutsys-wms-frontend'
//   }
// };


export const environment = {
  projectConfig: {
    maxControllevel: 3
  },
  encryptSecretKey: 'U2FsdGVkX1+c4jKKTR75pv3LvFyEGcAzzRsCeuRhxrk=',
  appName: 'YUTSYS',
  production: false,
  version: env.npm_package_version + '-dev',
  service: {
    core: {
      identity: host.public + ':50001',
      logging: host.public + ':50002',
      storage: host.public + ':50003',
      user: host.public + ':50006',
      identitySignalR: host.public + ':50007',
      loggingSignalR: host.public + ':50008',
      signalR: host.public + ':50111'
    },
    wms: {
      definition: host.public + ':50101',
      inventory: host.public + ':50102',
      item: host.public + ':50103',
      location: host.public + ':50104',
      project: host.public + ':50105',
      receiving: host.public + ':50106',
      outbound: host.public + ':50107',
      outboundSignalR: host.public + ':50108',
      tracking: host.public + ':50109',
    },

    wimMaster: host.public + ':8080/master-backend/api/v1',
    wimWms: host.public + '/wms-backend/api/v1'
  },
  defaultLanguage: 'en',
  supportedLanguages: [
    'en',
    'th',
    'jp'
  ],
  identityConfigs: {
    authorizeEndpoint: '/connect/authorize?',
    queryParams: {
      response_type: encodeURI('id_token token'),
      client_id: encodeURI(client_id),
      redirect_uri: encodeURI(location.origin),
      post_logout_redirect_uri: encodeURI(location.origin),
      scope: encodeURI('openid profile wms wmsreceiving'),
      nonce: '',
      state: ''
    }
  },
  encryptConfigs: {
    enable: false,
    pwd: 'OCYzM2xpZkxJSDNqZmcqRWY='
  }
};

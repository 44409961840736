import { Component, OnInit, Output, EventEmitter, Input, ViewChildren } from '@angular/core';
import { LocationService } from '@app/shared/services/location.service';
@Component({
  selector: 'app-form-autocomplete-location',
  templateUrl: './form-autocomplete-location.component.html',
  styleUrls: ['./form-autocomplete-location.component.scss']
})
export class FormAutocompleteLocationComponent implements OnInit {
  autoComLocationData: any[] = [];
  @Output() onLocationSelect = new EventEmitter<any>();
  @Output() onLocationEnter = new EventEmitter<any>();
  @Output() onLocationBlur = new EventEmitter<any>();
  @Input() results = { locationCode: '', locationId: 0, value: 0, label: '' };
  constructor(
    private locationService: LocationService
  ) {

  }

  ngOnInit() {
  }


  onSearchLocation(event: any) {
    console.log(event);
    this.locationService.getAutoComLocation(event.query).subscribe((data: any) => {
      this.autoComLocationData = data.Result;
      this.autoComLocationData.forEach(element => {
        Object.assign(element, { value: element.locationId, label: element.locationCode });
      });
    });
  }

  _onLocationSelect(event: any) {
    console.log('_onLocationSelect event', event);
    this.results = event;
    this.onLocationSelect.emit(event);
    this.autoComLocationData = [];
  }

  _onLocationEnter(event: any) {
    // this.results = event;
    if (this.autoComLocationData.length > 0) {
      this.results = this.autoComLocationData[0];
      // this.autoCompleteLocation.hide();
      this.onLocationEnter.emit(event);
      this.autoComLocationData = [];
    } else {
      this.results = { locationCode: '', locationId: 0, value: 0, label: '' };
    }
  }

  _onLocationBlur(event: any) {
    this.onLocationBlur.emit(event);
  }

  _onUnselect() {
    this.results = { locationCode: '', locationId: 0, value: 0, label: '' };
  }

}

import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { SignalrService } from '@app/shared/services/signalr-service';
import { OrderStatus } from '@app/shared/models/outbound/order-status';
import { ReceiveStatus } from '@app/shared/models/receive/receive-status';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @ViewChild('contentTemplate') contentTemplate: TemplateRef<any>;

  totalBadges = 0;
  // receive
  receives: any[] = [];
  receiveQty = 0;
  unTransferReceivePlans = 0;
  unConfirmReceives = 0;

  // order
  orders: any[] = [];
  orderQty = 0;
  unConfirmOrders = 0;
  unAllocateOrders = 0;
  unInspectOrders = 0;
  unShipOrders = 0;

  subscribe: any;

  constructor(
    private signalrService: SignalrService,
  ) { }

  ngOnInit() {
    console.log('oninti');
    console.log(this.signalrService.isConnect);
    if (this.signalrService.isConnect) {
      this.signalrService.getNotifications();
    } else {
      this.signalrService.retry();
    }
    this.receiveNotification();
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    console.log('destroy');
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
  }

  receiveNotification() {
    this.subscribe = this.signalrService.notifications.subscribe((res: any) => {
      if (res) {
        this.restart();
        // this.totalBadges = res.map(m => m.count).reduce((a, b) => a + b, 0);
        const orders = res.filter(f => f.module === 'Outbound');
        console.log(orders);
        orders.forEach(element => {
          switch (element.status) {
            case OrderStatus._New:
              this.unConfirmOrders = element.count;
              break;
            case OrderStatus._Confirmed:
              this.unAllocateOrders = element.count;
              break;
            case OrderStatus._Allocated:
              this.unInspectOrders = element.count;
              break;
            case OrderStatus._Inspecting:
              this.unShipOrders += element.count;
              break;
            case OrderStatus._Inspected:
              this.unShipOrders += element.count;
              break;
            default:
              break;
          }
          this.orderQty += element.count;
        });

        this.orders = [{ text: 'Unconfirmed Orders', qty: this.unConfirmOrders }
          , { text: 'Unallocated Orders', qty: this.unAllocateOrders }
          , { text: 'Uninspected Orders', qty: this.unInspectOrders }
          , { text: 'Unshipped Orders', qty: this.unShipOrders }];

        const receives = res.filter(f => f.module.indexOf('Receive') > -1);
        receives.forEach(element => {
          switch (element.status) {
            case ReceiveStatus.Planning:
              this.unTransferReceivePlans = element.count;
              break;
            case ReceiveStatus.ReceiveInspected:
              this.unConfirmReceives += element.count;
              break;
            case ReceiveStatus.Putaway:
              this.unConfirmReceives += element.count;
              break;
            case ReceiveStatus.PatialPutaway:
              this.unConfirmReceives += element.count;
              break;
            default:
              break;
          }
          this.receiveQty += element.count;
        });

        this.receives = [{ text: 'Uninspected Receives', qty: this.unTransferReceivePlans }
          , { text: 'Unconfirmed Receives', qty: this.unConfirmReceives }];

        this.totalBadges = this.orderQty + this.receiveQty;

      }
    });
  }

  restart() {
    this.totalBadges = 0;

    this.orderQty = 0;
    this.unConfirmOrders = 0;
    this.unAllocateOrders = 0;
    this.unInspectOrders = 0;
    this.unShipOrders = 0;

    this.receiveQty = 0;
    this.unTransferReceivePlans = 0;
    this.unConfirmReceives = 0;
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injectable } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpRequest, HttpInterceptor, HttpEvent, HttpHandler } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule, TranslateLoader, } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeModule } from '@app/home/home.module';
import { LoginModule } from '@app/login/login.module';
import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ModulesModule } from '@app/modules/modules.module';
import { Observable } from 'rxjs';
import { PopupModalComponent } from '@app/shared/components/popup/popup-modal/popup-modal.component';
import { PageNotFoundComponent } from '@app/page-not-found/page-not-found.component';
import { APP_BASE_HREF } from '@angular/common';
import { ProjectService } from '@app/shared/services/project.service';
import { SparefieldService } from '@app/shared/services/sparefield-service';
import { ProjectConfigService } from './shared/services/project-config.service';
import { SortBranchComponent } from './components/sort-branch/sort-branch.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http.disableApiPrefix(), environment.service.wms.project + '/public/lang/', '.json?v=' + Math.random() + '' + Date.now());
}

@Injectable()
export class NoopInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
  }
}


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgbModule.forRoot(),
    CoreModule.forRoot(),
    SharedModule,
    ModulesModule,
    HomeModule,
    LoginModule,
    AppRoutingModule,
    AngularFontAwesomeModule
  ],
  declarations: [
    AppComponent,
    PopupModalComponent,
    PageNotFoundComponent,
    SortBranchComponent
  ],
  exports: [TranslateModule, PopupModalComponent],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    PopupModalComponent,
    ProjectService,
    ProjectConfigService,
    SparefieldService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

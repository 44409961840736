import { Component, OnInit } from '@angular/core';
import { IdentityService } from '@app/core/authentication/identity.service';

@Component({
  selector: 'app-project-switcher',
  templateUrl: './project-switcher.component.html',
  styleUrls: ['./project-switcher.component.scss']
})
export class ProjectSwitcherComponent implements OnInit {

  public projectName = '';

  constructor(
    private identityService: IdentityService
  ) { }

  ngOnInit() {
    this.projectName = this.identityService.getProjectName();
  }

  onChangeCustomer() {
    this.identityService.SelectCustomer();
  }

}

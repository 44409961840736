import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract, AuthenticationGuard, Route } from '@app/core';
import { ModulesComponent } from '@app/modules/modules.component';
import { SelectProjectComponent } from './admin/project-config-admin/select-project/select-project.component';
import { ViewTrackingComponent } from './tracking/view-tracking/view-tracking.component';

const routes: Routes = [
  { path: '', redirectTo: '/modules', pathMatch: 'full' },
  { path: 'modules', component: ModulesComponent, canActivate: [AuthenticationGuard], data: { title: extract('Modules') } },
  { path: 'modules/admin/selectproject', component: SelectProjectComponent, data: { title: extract('Select Project'), breadcrumb: 'Select Project' } },
  Route.withShell([
    { path: 'modules/definition', loadChildren: 'app/modules/definition/definition.module#DefinitionModule', data: { title: extract('Definition'), breadcrumb: 'Definition' } },
    { path: 'modules/master', loadChildren: 'app/modules/master/master.module#MasterModule', data: { title: extract('Master'), breadcrumb: 'Master' } },
    { path: 'modules/admin/master', loadChildren: 'app/modules/admin/admin-master/admin-master.module#AdminMasterModule', data: { title: extract('Master Management'), breadcrumb: 'Master Management' } },
    { path: 'modules/inbound', loadChildren: 'app/modules/inbound/inbound.module#InboundModule', data: { title: extract('Inbound'), breadcrumb: 'Inbound' } },
    { path: 'modules/inventory', loadChildren: 'app/modules/inventory/inventory.module#InventoryModule', data: { title: extract('Inventory'), breadcrumb: 'Inventory' } },
    { path: 'modules/projectconfig', loadChildren: 'app/modules/project-config/project-config.module#ProjectConfigModule', data: { title: extract('Project Config'), breadcrumb: 'Project Config' } },
    // tslint:disable-next-line:max-line-length
    { path: 'modules/admin/projectconfig', loadChildren: 'app/modules/admin/project-config-admin/project-config-admin.module#ProjectConfigAdminModule', data: { title: extract('Project Config'), breadcrumb: 'Project Config' } },
    { path: 'modules/admin/user', loadChildren: 'app/modules/admin/user/user.module#UserModule', data: { title: extract('User & Role'), breadcrumb: 'User & Role' } },
    { path: 'modules/outbound', loadChildren: 'app/modules/outbound/outbound.module#OutboundModule', data: { title: extract('Outbound'), breadcrumb: 'Outbound' } },
    { path: 'modules/admin/logging', loadChildren: 'app/modules/admin/logging/logging.module#LoggingModule', data: { title: extract('Logging'), breadcrumb: 'Logging' } },
    { path: 'modules/tracking', loadChildren: 'app/modules/tracking/tracking.module#TrackingModule' }
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModulesRoutingModule { }

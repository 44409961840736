
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Status } from '@app/shared/models/tracking/status.model';
import { IdentityService } from '@app/core/authentication/identity.service';
import { StorageService } from '@app/core/storage.service';
import { isNil } from 'lodash';

@Injectable()
export class ProjectService {


  constructor(private http: HttpClient,
    private identityService: IdentityService,
    private storage: StorageService) {

  }

  getProjectList(): Observable<any> {
    return this.http.auth().get(environment.service.wms.project + '/projects');
  }

  getProject(id: string) {
    return this.http.auth().get(environment.service.wms.project + '/projects/' + id);
  }

  getProjectConfig() {
    const projectId = this.storage.retrieve('projectId');
    const projectName = this.identityService.getProjectName();

    if (projectName === 'Admin Project' && !isNil(projectId)) {
      return this.http.auth().get(environment.service.wms.project + '/configs', { params: { projectId: projectId } });
    } else {
      return this.http.auth().get(environment.service.wms.project + '/configs');
    }
  }

  getCheckProjectConfigVersion(version: number) {
    return this.http.auth().get(environment.service.wms.project + '/configs/version?v=' + version);
  }

  postProject(body: any) {
    return this.http.auth().post(environment.service.wms.project + '/projects', body);
  }

  checkDuplicateProject(body: any) {
    return this.http.auth().post(environment.service.wms.project + '/projects/checkduplicate/ ', body);
  }

  putProject(body: any) {
    return this.http.auth().put(environment.service.wms.project + '/projects', body);
  }

  resetToProjectSettingToDefault(body: any) {
    return this.http.auth().put(environment.service.wms.project + '/projects/reset/', body);
  }

  putEditProjectConfig(body?: any) {
    const projectId = this.storage.retrieve('projectId');
    const projectName = this.identityService.getProjectName();

    if (projectName === 'Admin Project' && !isNil(projectId)) {
      return this.http.auth().put(environment.service.wms.project + '/configs', body, { params: { projectId: projectId } });
    } else {
      return this.http.auth().put(environment.service.wms.project + '/configs', body);
    }
  }


  getTableControl() {
    return this.http.get(environment.service.wimMaster + '/TableControl');
  }

  getSpareField() {
    return this.http.get(environment.service.wimWms + '/SpareField');
  }

  clearAllocationBlock() {
    return this.http.auth().get(environment.service.wms.outbound + '/allocate/clearuser');
  }
  getMenu(Roleid: string) {
    return this.http.auth()
      .get(environment.service.wms.project + '/menumanage/mainmenu/role/' + Roleid);
  }
  onSave(data?: any) {
    return this.http.auth()
      .post(environment.service.wms.project + '/menumanage/rolemenu', data);

  }

  fineRole(Roleid: string) {
    return this.http.auth()
      .get(environment.service.wms.project + '/menumanage/findrole/' + Roleid);

  }
  onDel(Roleid: string) {
    return this.http.auth()
      .delete(environment.service.wms.project + '/menumanage/rolemenu/' + Roleid);
  }
  getMenuShow(Domain: string) {
    return this.http.auth()
      .get(environment.service.wms.project + '/menumanage/mainmenu/' + Domain);
  }

  getAutoMailConfig() {
    return this.http.auth()
      .get(environment.service.wms.project + '/configs/AutoEmail');
  }
  postSaveAutomailConfig(data: any) {
    return this.http.auth()
      .post(environment.service.wms.project + '/configs/AutoEmail', data);
  }
}


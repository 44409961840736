import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: "sortBy" })
export class SortPipe {
	transform(array: Array<any>, args: string, sortingType: string = 'asc'): Array<string> {
		array.sort((a: any, b: any) => {
			if (a[args] < b[args]) {
				return (sortingType === 'asc') ? -1 : 1;
			} else if (a[args] > b[args]) {
				return (sortingType === 'asc') ? 1 : -1;;
			} else {
				return 0;
			}
		});
		return array;
	}
}
import { Component, Input, OnInit } from '@angular/core';
import { IdentityService } from '@app/core/authentication/identity.service';
import { MODULES_MENU_ITEMS } from '@app/modules/modules-menu-item';
import { MODULES_ADMIN_MENU_ITEMS } from '@app/modules/modules-admin-menu-items';
import { Router } from '@angular/router';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core/animations/route.animations';
import { IdentitySignalrService } from '@app/core/authentication/identity-signalr.service';
import { LoggingSignalrService } from '@app/core/logging-signalr.service';
import { ModulesMenu } from './modules-menu.model';
import * as _ from 'lodash';
import { ReceiveService } from './inbound/receive.service';
import { OrderService } from './outbound/order/order.service';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { StorageService } from '@app/core/storage.service';
import { MenuService } from '@app/core/shell/menu/menu.service';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit {

  @Input() position = 'normal';
  public routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  public moduleMenuItems = [];
  public moduleAdminMenuItems = [];
  public user: any;
  public authenticated = false;
  public canAccessAdminMenu = false;

  receiveCount: number;
  orderCount: number;

  constructor(
    private identityService: IdentityService,
    private identitySignalrService: IdentitySignalrService,
    private loggingSignalrService: LoggingSignalrService,
    private router: Router,
    private _receiveService: ReceiveService,
    private _orderService: OrderService,
    private _projectConfigService: ProjectConfigService,
    private _storageService: StorageService,
    private moduleMenuService: MenuService,
  ) {
  }

  ngOnInit() {
    this.moduleMenuService.getMenuShowdata();
    // console.log('1');
    this.authenticated = this.identityService.isAuthorized;
    if (this.authenticated) {
      // console.log('2');
      this.user = this.identityService.userData;
      this.canAccessAdminMenu = this.identityService.getProjectName() === 'Admin Project' && this.identityService.isAdmin();
      if (this.canAccessAdminMenu) {
        // console.log('3');
        this.moduleAdminMenuItems = MODULES_ADMIN_MENU_ITEMS;
        this._storageService.clearBy('projectId');
      } else {
        // console.log('4');
        if (this.identityService.isAdmin()) {
          // this.moduleMenuItems = MODULES_MENU_ITEMS;
          // this.getBedges();
          // console.log('5');
          this.moduleMenuService.eventEmitter.subscribe((res: any) => {
            this.moduleMenuItems = [];
            this.moduleMenuItems = this.moduleMenuService.findMainMenu(0);
            console.log(this.moduleMenuItems);
            this.getBedges();
          });
        } else {
          this.moduleMenuService.eventEmitter.subscribe((res: any) => {
            this.moduleMenuItems = [];
            this.moduleMenuItems = this.moduleMenuService.findMainMenu(0);
            this.getBedges();
          });
          // MODULES_MENU_ITEMS
          // this.moduleMenuItems = [];
          // MODULES_MENU_ITEMS.forEach((menu: ModulesMenu) => {
          //   const isHiddenForOperation = this.identityService.isOperation() && !_.isNil(menu.forAdmin);
          //   if (!isHiddenForOperation) {
          //     this.moduleMenuItems.push({
          //       title: menu.title,
          //       icon: menu.icon,
          //       link: menu.link,
          //       badge: menu.badge
          //     });
          //   }
          // });
          // this.getBedges();
        }
      }
      this._projectConfigService.checkVersion();
    }

    this.loggingSignalrService.msgReceived$
      .subscribe(msg => console.log('msgReceived$', msg));
  }

  getBedges() {
    this.moduleMenuItems.map((m) => {
      if (m.title.indexOf('Inbound') > -1 && m.badge) {
        this._receiveService.getUnconfirmReceive()
          .subscribe((r: any) => { r.Result ? m.badge = r.Result + '+' : m.badge = null; });
      } else if (m.title.indexOf('Outbound') > -1 && m.badge) {
        this._orderService.getUnconfirmOrder()
          .subscribe((o: any) => { o.Result ? m.badge = o.Result + '+' : m.badge = null; });
      }
    });
  }

  onSelectModule(url: string) {
    this.router.navigate([url]);
  }

  testRabbit() {
    this.identityService.testRabbit();
  }

}

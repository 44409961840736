import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AddressService } from '@app/shared/services/address.service';

@Component({
  selector: 'app-form-address2',
  templateUrl: './form-address2.component.html',
  styleUrls: ['./form-address2.component.css']
})
export class FormAddress2Component implements OnInit {
  @Input() result = { zipcodeEn: '', provinceEn: '', cityEn: '', subCityEn: '', addressEn: '' };
  constructor(
    public addressService: AddressService
  ) { }

  ngOnInit() {
  }

  @HostListener('keypress', ['$event'])
  NotEn(e: any) {
    // const pattern = /([\w])/gi;
    // if (!pattern.test(String.fromCharCode(e.keyCode))) {
    //   e.preventDefault();
    // }
  }

  chkPaste(e: any) {
    // const pattern = /([\w])/gi;
    // const content = e.clipboardData.getData('text/plain');
    // console.log(pattern.test(content));
    // if (!pattern.test(content)) {
    //   e.preventDefault();
    // }
  }

}

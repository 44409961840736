import { Component, OnInit } from '@angular/core';
import { UserActivityLogService } from '@app/shared/components/user-activity-log/user-activity-log.service';
import { IdentityService } from '@app/core/authentication/identity.service';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core/animations/route.animations';

@Component({
  selector: 'app-user-activity-log',
  templateUrl: './user-activity-log.component.html',
  styleUrls: ['./user-activity-log.component.scss']
})
export class UserActivityLogComponent implements OnInit {
  public routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  logDetail: any;
  user: any;
  authenticated: any;

  constructor(
    private userLogService: UserActivityLogService,
    private identityService: IdentityService
  ) { }

  ngOnInit() {
    this.authenticated = this.identityService.isAuthorized;
    if (this.authenticated) {
      this.user = this.identityService.userData;
      this.userLogService.getLogDetailList().subscribe((res: any) => {
        this.logDetail = res;
      });
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHelper } from '@app/core/helper/http/http.helper';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  httpHelper = new HttpHelper();
  constructor(
    private http: HttpClient,
  ) {
  }

  env = environment;
  getLocationList() {
    return this.http.auth().get(environment.service.wms.location + '/locations');
  }
  getGroupLocationList() {
    return this.http.auth().get(environment.service.wms.location + '/grouplocations/dto');
  }
  getLocationByID(url: string, body?: any) {
    return this.http.auth().get(environment.service.wms.location + url, body);
  }

  getAutoComLocation(term: string) {
    return this.http.auth().get(environment.service.wms.location + '/locations/autocomplete/' + term);
  }
  getLocationByItemCode(itemCode: string) {
    return this.http.auth().get(environment.service.wms.location + '/locations/getlocationsuggestion?itemCode=' + itemCode);
  }

  getLocationByGroupLocID(id: any) {
    return this.http.auth().get(environment.service.wms.location + '/locations/search/' + id);
  }

  postCreateLocation(body?: any) {
    return this.http.auth().post(environment.service.wms.location + '/locations', body)
      ;
  }

  putEditLocation(id: string, body?: any) {
    return this.http.auth().put(environment.service.wms.location + '/locations/' + id, body);
  }


}


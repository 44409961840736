import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from '@app/shared/components/loader/loader.component';
import { PrimengModule } from '@app/shared/plugin-module/primeng-module';
import { ConfirmationService } from 'primeng/api';

import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbAlertModule,
  NbSpinnerModule
} from '@nebular/theme';

import { DragDropModule } from '@angular/cdk/drag-drop';



import { MessageService } from 'primeng/components/common/messageservice';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SupplierManageModalComponent } from './components/supplier-manage-modal/supplier-manage-modal.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormValidateDirective } from '@app/shared/components/form/form-validate/form-validate.directive';
import { FormAddressComponent } from '@app/shared/components/form/form-address/form-address.component';
import { AddressService } from '@app/shared/services/address.service';
import { SortPipe } from '@app/shared/helper/sort-pipe';
import { ErrorModalComponent } from './components/popup/error-modal/error-modal.component';
import { FormAddress2Component } from './components/form/form-address2/form-address2.component';
import { AdminBackBarComponent } from './components/admin-back-bar/admin-back-bar.component';
import { HeaderPageComponent } from './components/header-page/header-page.component';
import { EnterToTabDirective } from './components/enter-to-tab/enter-to-tab.directive';
import { ConfirmModalComponent } from './components/popup/confirm-modal/confirm-modal.component';
import { FormAutocompleteLocationComponent } from './components/form/form-autocomplete-location/form-autocomplete-location.component';
import { InputAutocompleteComponent } from './components/form/input-autocomplete/input-autocomplete.component';
import { FilterComponent } from './components/form/filter/filter.component';
import { NgbDatepickerModule, NgbRatingModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule, TimepickerModule } from 'ngx-bootstrap';
import { DynamicFormsCoreModule } from '@ng-dynamic-forms/core';
import { DynamicFormsBootstrapUIModule } from '@ng-dynamic-forms/ui-bootstrap';
import { DynamicFormsNGBootstrapUIModule } from '@ng-dynamic-forms/ui-ng-bootstrap';
import { DynamicFormsPrimeNGUIModule } from '@ng-dynamic-forms/ui-primeng';
import { DynamicFilterComponent } from './components/form/dynamic-filter/dynamic-filter.component';
import { GridsterModule } from 'angular-gridster2';

const NB_MODULES = [
  NbAlertModule,
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbSpinnerModule
];

const NG_BOOTSTRAP_MODULES = [
  NgbModule
];

@NgModule({
  imports: [
    CommonModule,
    PrimengModule,
    TranslateModule,
    GridsterModule,
    NB_MODULES,
    NG_BOOTSTRAP_MODULES,
    FormsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgbDatepickerModule,
    NgbRatingModule,
    NgbTimepickerModule,
    DynamicFormsCoreModule.forRoot(),
    DynamicFormsBootstrapUIModule,
    DynamicFormsNGBootstrapUIModule,
    DynamicFormsPrimeNGUIModule,
    DragDropModule,
  ],
  declarations: [
    LoaderComponent,
    FormValidateDirective,
    EnterToTabDirective,
    SupplierManageModalComponent,
    FormAddressComponent,
    FormAddress2Component,
    SortPipe,
    ErrorModalComponent,
    ConfirmModalComponent,
    AdminBackBarComponent,
    HeaderPageComponent,
    FormAutocompleteLocationComponent,
    InputAutocompleteComponent,
    FilterComponent,
    DynamicFilterComponent
  ],
  exports: [
    LoaderComponent,
    PrimengModule,
    GridsterModule,
    TranslateModule,
    NB_MODULES,
    NG_BOOTSTRAP_MODULES,
    FormValidateDirective,
    EnterToTabDirective,
    FormAddressComponent,
    FormAddress2Component,
    SortPipe,
    AdminBackBarComponent,
    HeaderPageComponent,
    FormAutocompleteLocationComponent,
    InputAutocompleteComponent,
    FilterComponent,
    DynamicFilterComponent,
    DragDropModule,
  ],
  providers: [
    ConfirmationService,
    MessageService,
    AddressService,
    NgbActiveModal,
  ],
  entryComponents: [
    SupplierManageModalComponent,
    ErrorModalComponent,
    ConfirmModalComponent
  ]
})
export class SharedModule { }

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class HttpHelper {
    objToHttpParams(obj: Object): HttpParams {
        return Object.getOwnPropertyNames(obj)
            .reduce((p, key) => p.set(key, obj[key]), new HttpParams());
    }

    setFormData(options: object) {
        const formData = new FormData();
        // tslint:disable-next-line:forin
        for (const key in options) {
            formData.append(key, options[key]);
        }
        return formData;
    }
}

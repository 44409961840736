import { SpareFieldDetailModel } from '../item-master/spare-field-detail-model';

export class Supplier {

    constructor(
        public id: number,
        public supplierCode: string,
        public supplierName: string,
        public pic?: string,
        public address?: string,
        public zipCode?: string,
        public phoneNumber?: string,
        public faxNumber?: string,
        public spareFieldDetails?: SpareFieldDetailModel[]
    ) { }
}

import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '@app/core';
import { StorageService } from '@app/core/storage.service';
import { IdentityService } from '@app/core/authentication/identity.service';
import { routeAnimations } from '@app/core/animations/route.animations';
import { AnimationsService } from '@app/core/animations/animations.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, OnDestroy {

  public isAuthorized = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private storage: StorageService,
    private identity: IdentityService,
    // private signalrService: SignalrService,
    private animationService: AnimationsService
  ) { }

  ngOnInit() {
    this.animationService.updateRouteAnimationType(
      false,
      true
    );

    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    // if (!this.translateService.getLangs().length) {
    //   this.langFileEn = JSON.parse(localStorage.getItem('userData')).projectId + '_en';
    //   this.translateService.setDefaultLang(this.langFileEn);
    //   this.translateService.use(this.langFileEn);
    // }
    // if (localStorage.getItem('lang')) {
    //   this.translateService.use(localStorage.getItem('lang'));
    // }



    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }

        let lang = this.storage.retrieve('lang');
        lang = lang ? lang : environment.defaultLanguage;
        const path = this.identity.getProjectId() + '_' + lang;
        // #JobComment, ใช้ชั่วคราว  รำคาญ Error Lang
        // const path = '1_' + lang;
        this.translateService.use(encodeURI(path));

      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { RoleModel } from './role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(
    private http: HttpClient
  ) { }

  public getRoles() {
    return this.http.auth().get(environment.service.core.user + '/roles');
  }

  public getRolesByProject(projectId: string) {
    return this.http.auth().get(environment.service.core.user + '/roles/project/' + projectId);
  }

  public getRole(id: string) {
    return this.http.auth().get(environment.service.core.user + '/roles/' + id);
  }

  public submitRole(role: RoleModel, permissionIds: number[]) {
    if (role.roleId !== '') {
      return this.http.auth().put(environment.service.core.user + '/roles/' + role.roleId, {
        roleId: role.roleId,
        name: role.name,
        projectId: role.projectId,
        permissionIds: permissionIds
      });
    } else {
      return this.http.auth().post(environment.service.core.user + '/roles', {
        roleId: '',
        name: role.name,
        projectId: role.projectId,
        permissionIds: permissionIds
      });
    }
  }

}

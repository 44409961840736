import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Status } from '@app/shared/models/tracking/status.model';

@Injectable({
    providedIn: 'root'
})
export class AutocompleteService {

    constructor(private http: HttpClient) { }
    filter(endpoint: string, term: string) {
        let baseUrl = '';
        switch (endpoint) {
            case 'items':
            case 'units':
            case 'categories':
            case 'suppliers':
                baseUrl = environment.service.wms.item;
                break;
            case 'locations':
                baseUrl = environment.service.wms.location;   
                break;        
            case 'customers':
            case 'customers/end-customers':
            case 'countries':
                baseUrl = environment.service.wms.project;   
                break;         
        }
        return this.http.auth()
            .get(`${baseUrl}/${endpoint}/autocomplete/${term}`);
    }

}

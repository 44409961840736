import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IdentityService } from '@app/core/authentication/identity.service';
import Swal from 'sweetalert2';

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(
    private identityService: IdentityService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (response instanceof HttpErrorResponse) {
      switch (response.status) {
        case 401:
        case 403:
          this.identityService.HandleError(response.status);
          break;
        case 500:
          // console.log('response.error', response.error);
          // #JobComment, Error ยาวไป
          // if (response.error.ResponseException) {
          //   str += '<p><b>Error Details</b>: ' + response.error.ResponseException.Details + '<p>';
          //   str += '<p><b>Exception Message</b>: ' + response.error.ResponseException.ExceptionMessage + '<p>';
          // 
          const errorMessage = (response.error.ResponseException ? 
            response.error.ResponseException.ExceptionMessage 
            : response.error.Message);
          Swal({
            title: 'Error!',
            text: errorMessage,
            type: 'error',
            confirmButtonText: 'Close'
          });

          break;
        default:
          break;
      }
    }
    throw response;
  }
}

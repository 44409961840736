import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Supplier } from '@app/shared/models/supplier/supplier.model';
import { SupplierService } from '@app/shared/services/supplier.service';
import { ReceiveService } from '@app/modules/inbound/receive.service';

@Component({
  selector: 'app-supplier-manage-modal',
  templateUrl: './supplier-manage-modal.component.html',
  styleUrls: ['./supplier-manage-modal.component.scss']
})
export class SupplierManageModalComponent implements OnInit {

  @Output() supplier: Supplier;
  @Output() sendInfo: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    public supplierService: SupplierService
  ) {
    this.supplier = new Supplier(0, '', '');
  }

  ngOnInit() {
  }

  onSubmit() {

    this.supplierService.postCreate(this.supplier).subscribe((res) => {
      if (res !== undefined) {
        this.supplierService.searchSupplierBySupCode(this.supplier.supplierCode).subscribe((res2: any) => {
          console.log(res2.Result);
          this.sendInfo.next({ state: 'created' });
        });
      }
      this.activeModal.close();

    });
  }

}

import { NgModule } from '@angular/core';
import {
  DataTableModule
  , CheckboxModule
  , DropdownModule
  , FileUploadModule
  , GrowlModule,
  ConfirmDialogModule,
  AutoCompleteModule,
  DialogModule,
  PanelModule,
  ListboxModule,
  CalendarModule,
  PickListModule,
  InputMaskModule,
  PaginatorModule,
  SidebarModule,
  DataScrollerModule,
  KeyFilterModule,
  RadioButtonModule,
  TabViewModule,
  OrderListModule,
  AccordionModule,
  MultiSelectModule,
  ChipsModule
} from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

@NgModule({
  imports: [
    DataTableModule,
    CheckboxModule,
    DropdownModule,
    FileUploadModule,
    GrowlModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    DialogModule,
    PanelModule,
    ListboxModule,
    CalendarModule,
    PickListModule,
    InputMaskModule,
    TableModule,
    PaginatorModule,
    SidebarModule,
    ToastModule,
    DataScrollerModule,
    TabViewModule,
    OrderListModule,
    KeyFilterModule,
    RadioButtonModule,
    AccordionModule,
    MultiSelectModule,
    ChipsModule
  ],
  exports: [
    DataTableModule,
    CheckboxModule,
    DropdownModule,
    FileUploadModule,
    GrowlModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    DialogModule,
    PanelModule,
    ListboxModule,
    CalendarModule,
    PickListModule,
    InputMaskModule,
    TableModule,
    PaginatorModule,
    SidebarModule,
    ToastModule,
    DataScrollerModule,
    TabViewModule,
    OrderListModule,
    KeyFilterModule,
    RadioButtonModule,
    AccordionModule,
    MultiSelectModule,
    ChipsModule
  ]
})
export class PrimengModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModulesRoutingModule } from '@app/modules/modules-routing.module';
import { ModulesComponent } from '@app/modules/modules.component';
import { UserActivityLogComponent } from '@app/shared/components/user-activity-log/user-activity-log.component';
import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule
} from '@nebular/theme';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { LoggingListComponent } from './admin/logging-list/logging-list.component';
import { SelectProjectComponent } from './admin/project-config-admin/select-project/select-project.component';

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule
];

@NgModule({
  imports: [
    CommonModule,
    NB_MODULES,
    SharedModule,
    CoreModule,
    ModulesRoutingModule
  ],
  declarations: [ModulesComponent, UserActivityLogComponent, LoggingListComponent, SelectProjectComponent]
})
export class ModulesModule { }

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserActivityLogInterceptor implements HttpInterceptor {

    private _type: string;
    private _module: string;
    private _menu: string;
    private _refNo: string;
    private _link: string;

    constructor() { }

/**
 * Configures interceptor options
 * @param {{type: string}} options
 * @param {{module: string}} options
 * @param {{menu: string}} options
 * @param {{refNo: string}} options
 * @param {{link: string}} options
 * @return {UserActivityLogInterceptor} The configured instance.
 */
    configure(options?: { type?: string, module?: string, menu?: string, refNo?: string , link?: string } | null): UserActivityLogInterceptor {
        const instance = new UserActivityLogInterceptor();
        instance._type = (options && options.type) ? options.type : '';
        instance._module = (options && options.module) ? options.module : '';
        instance._menu = (options && options.menu) ? options.menu : '';
        instance._refNo = (options && options.refNo) ? options.refNo : '';
        instance._link = (options && options.link) ? options.link : '';
        return instance;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const activityParams = new HttpParams()
            .set('activityType', this._type)
            .set('activityModule', this._module)
            .set('activityMenu', this._menu)
            .set('activityRefNo', this._refNo)
            .set('activityLink', this._link);

        request = request
            .clone({
                url: request.url,
                params: activityParams
            });
        return next.handle(request);
    }
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AutocompleteService } from '@app/shared/services/autocomplete.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss']
})
export class InputAutocompleteComponent implements OnInit {
  filteredData: any[] = [];
  @Output() onSelect = new EventEmitter<any>();
  @Output() onEnter = new EventEmitter<any>();
  @Output() onBlur = new EventEmitter<any>();
  @Input() addditionField = '';
  @Input() endpoint = '';
  @Input() placeholder = 'Enter Keyword Here...';
  @Input() inputStyleClass = 'uk-input';
  @Input() filterCustom = ['', ''];
  @Input() filterSelected = { value: 0, text: '', additional: {} };
  @Input() isDisabled: boolean;


  showFieldBy = 'text';

  constructor(
    private autocomplete: AutocompleteService
  ) {
  }

  ngOnInit() {
    if (this.addditionField) { this.showFieldBy = 'additional.' + this.addditionField; }
  }

  onFilter(event: any) {
    this.autocomplete.filter(this.endpoint, event.query).subscribe((data: any) => {
      this.filteredData = data.Result;
      if (this.filterCustom[0] !== '' && this.filterCustom[1] !== '') {
        this.filteredData.forEach(element => {
          Object.assign(element, { value: element[`${this.filterCustom[0]}`], text: element[`${this.filterCustom[1]}`] });
        });
      }
    });
  }

  _onSelect(event: any) {
    if (typeof event === 'object') {
      this.filterSelected = { value: event.value, text: event.text, additional: event.additional };
    } else {
      if (this.filteredData.length > 0) {
        const filters = _.filter(this.filteredData, function (o: any) {
          return o.text.split(' ')[0].toUpperCase() === event.toUpperCase();
        });
        if (filters.length > 0) {
          this.filterSelected = this.filteredData[0];
        } else {
          this.filterSelected = { value: 0, text: '', additional: {} };
        }
      } else {
        this.filterSelected = { value: 0, text: '', additional: {} };
      }
    }
    this.onSelect.emit(this.filterSelected);
    this.filteredData = [];
  }

  _onEnter(event: any) {
    if (this.filteredData.length > 0) {
      this.filterSelected = this.filteredData[0];
    } else if (typeof this.filterSelected !== 'object') {
      this.filterSelected = { value: 0, text: '', additional: {} };
    }
    this.onEnter.emit(this.filterSelected);
    this.filteredData = [];
  }

  _onBlur(event: any) {
    setTimeout(() => {
      if ((this.filterSelected + '') === '') {
        this._onSelect({ value: 0, text: '' });
      } else if (typeof this.filterSelected !== 'object') {
        this._onSelect(this.filterSelected);
      }
      this.onBlur.emit(event);
    }, 300);

  }


  _onUnselect() {
    this.filterSelected = { value: 0, text: '', additional: {} };
  }

}

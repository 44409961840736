import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { ReportHelper } from '@app/core/helper/report/report.helper';
import { OrderHead } from '@app/shared/models/outbound/order-head';
import { isNil } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  env = environment;

  testSignalR() {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/order/123');
  }
  getOrderById(id?: any) {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/order/' + id);
  }

  getOrderByIdV2(id?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/getorder/', id);
  }


  postManualImportNewOrder(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/import', body);
  }

  postManualImportNewOrderContinue(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/import', body);
  }


  getOrderImportHistoryPdf(id: string, iframeId: string = 'iframe_pdf') {
    return this.http.auth()
      .disableApiResponse()
      .get(environment.service.wms.outbound + '/order/historyPdf/' + id, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        this.addFileToIframe(resp, iframeId);
      }
      );
  }

  getOrderImportHistory(id: string) {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/order/history/' + id);
  }

  getDefColumn(table: string) {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/order/orderjson/' + table);
  }

  getUnconfirmOrder() {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/order/unconfirmcount');
  }

  getPriceOrder(orderId: number) {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/order/summaryPrice/' + orderId);
  }

  removeMultipleOrders(id: number[]) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/', id);
  }

  cancelOrders(id: number) {
    return this.http.auth()
      .put(environment.service.wms.outbound + '/order/cancel', { Id: id });
  }

  cancelMultipleOrders(id: number[]) {
    return this.http.auth()
      .put(environment.service.wms.outbound + '/order/cancelmuliple', id);
  }

  postSearchOrderHead(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/search', body);
  }

  postSearchOrderHeadInDeletePage(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/searchDelete', body);
  }

  putRenewMultipleOrders(body?: any) {
    return this.http.auth()
      .put(environment.service.wms.outbound + '/order/recall/', body);
  }

  postCreateNewOrderItems(body?: any) {
    body.orderNumber = body.orderNumber.replace(/[^a-zA-Z0-9._() \-\/]/g, '');
    body.orderDate = body.orderDate ?
      body.orderDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null;

    if (isNil(body.newOrderDetails) == false) {
      body.newOrderDetails.forEach(od => {
        od.expireDate = od.expireDate ?
          od.expireDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null;

        od.receiveDate = od.receiveDate ?
          od.receiveDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null;

        od.manufacturingDate = od.manufacturingDate ?
          od.manufacturingDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null;

        od.bestBeforeDate = od.bestBeforeDate ?
          od.bestBeforeDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null;
      });
    }

    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/input', body);
  }

  putConfirmOrdersFromList(body?: any) {
    return this.http.auth()
      .put(environment.service.wms.outbound + '/order/confirmorders', { orderIds: body });
  }

  postCreateNewOrderItemsV2(body?: any) {
    body.orderNumber = body.orderNumber.replace(/[^a-zA-Z0-9._() \-\/]/g, '');
    body.orderDate = body.orderDate ?
      body.orderDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null;

    if (isNil(body.newOrderDetails) == false) {
      body.newOrderDetails.forEach(od => {
        od.expireDate = od.expireDate ?
          od.expireDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null;

        od.receiveDate = od.receiveDate ?
          od.receiveDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null;

        od.manufacturingDate = od.manufacturingDate ?
          od.manufacturingDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null;

        od.bestBeforeDate = od.bestBeforeDate ?
          od.bestBeforeDate.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) : null;
      });
    }

    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/input/v2/', body);
  }

  deleteOrderDetail(body?: any) {
    return this.http.auth()
      .delete(environment.service.wms.outbound + '/order/orderdetail/' + body);
  }

  deleteMultipleOrderDetail(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/removeorderdetail', body);
  }

  putOrderDetail(body?: any) {
    return this.http.auth()
      .put(environment.service.wms.outbound + '/order/orderdetail/', body);
  }

  pagingOrderDetail(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/orderdetail/', body);
  }

  searchOrderDetail(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/searchorderdetail/', body);
  }



  postSetOrderItemsStatusConfirmed(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/input/confirmed', body);
  }

  public downloadFile(data: any) {
    let name = data.headers.get('content-disposition');
    name = name.substring(name.indexOf('=') + 1).replace(/[:*?"<>|/\\]/g, '');
    name = name.substring(0, name.indexOf(';'));
    const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = name;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    URL.revokeObjectURL(url);
  }

  public addFileToIframe(data: any, iframeId: string) {

    const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
    const url = window.URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.width = '100%';
    iframe.height = '700px';
    iframe.src = url;
    document.getElementById(iframeId).innerHTML = '';
    document.getElementById(iframeId).appendChild(iframe);
  }


  public exportToPDFPopup(data: any): void {
    const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
    const url = URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  postSearchNewGroupOrderHead(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/searchgroup', body);
  }

  postSearchNewOrderHeadPdf(body?: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.outbound + '/order/searchPdf', body, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }

  postSearchNewOrderDetailPdf(body?: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.outbound + '/order/searchDetailPdf', body, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      }
      );
  }

  getAutoComOrder(keyword: any) {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/order/autocomplete/' + keyword);
  }

  genOrderNumber() {
    return this.http
      .auth()
      // .disableApiResponse()
      .get(environment.service.wms.outbound + '/order/genOderNo');
  }
  /**
   * Item
   */

  postSearchItemMaster(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/items/search', body);
  }

  getAllReceiveQuality() {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/items/receiveQuality');
  }

  getAutoSpeacialProduct(keyword: string) {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/items/specialautocomplete/' + keyword);
  }






  /**
   * Allocate
   */

  postSearchGroupOrderForAllocate(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/allocate/searchgroup', body);
  }

  postSearchGroupOrderForShipping(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/shipping/searchgroup', body);
  }

  postSearchGroupOrderForView(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/allocate/searchgroupview', body);
  }

  postSearchOrderForAllocate(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/allocate/searchhead', body);
  }

  postSearchOrderHeadInCancelAllocatePage(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/allocate/searchcancel', body);
  }

  postAllocateDetailByOrderIds(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/allocate/allocatebyorder', body);
  }

  cancelAllocateMultipleOrders(id: number[]) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/allocate/', id);
  }

  postConfirmOrderForAllocate(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/allocate/confirmallocate', body);
  }

  /***
  * Order ItemSet
  */

  getOrderItemSetById(id: number) {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/order/orderItemSet/' + id);
  }

  getItemSetById(id: number) {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/items/itemSet/' + id);
  }

  getAutoItemSet(term: string) {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/items/itemsetautocomplete/' + term);
  }

  getGenerateReceiveItemSetNo() {
    return this.http.auth()
      .get(environment.service.wms.outbound + '/order/orderItemSet/genitemsetorder');
  }

  postSaveReceiveItemSet(body: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/orderItemSet', body);
  }

  postItemSetToInventory(body: any) {
    return this.http.auth()
    .post(environment.service.wms.outbound + '/order/itemsetToInventory', body);
  }

  postorderitemsetcancel(body: any) {
    return this.http.auth()
    .post(environment.service.wms.outbound + '/order/orderitemsetcancel', body);
  }


  /***
   * Report
   */

  postGenOutboundWarranty(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.outbound + '/order/warranty', body);
  }

  postGenOutboundWarrantyPDF(body?: any) {
    return this.http.auth()
      .disableApiResponse()
      .post(environment.service.wms.outbound + '/order/warrantyPdf/', body, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        ReportHelper.exportToPDFPopup(resp);
      });
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ModelPersonMT } from '../models/user/model-person-mt';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private http: HttpClient) {
  }

  getUserList() {
    return this.http.get(environment.service.wimMaster + '/Users');
  }

  getUserByID(url: string, body?: any) {
    return this.http.get(environment.service.wimMaster + url, body);
  }

  getKeyAccessMobile(url: string, body?: any) {
    return this.http.get(environment.service.wimMaster + url, body);
  }

  postCreateUser(body?: any) {
    return this.http.post(environment.service.wimMaster + '/Users/', body);
  }

  putEditUser(body?: any) {
    return this.http.put(environment.service.wimMaster + '/Users/', body);
  }

  postChangePassword(body?: any) {
    return this.http.post(environment.service.wimMaster + '/Account/ChangePassword/', body);
  }

  getPersonProfile() {
    return this.http.get(environment.service.wimMaster + '/Persons/');
  }

  getPersonByID(id?: any) {
    return this.http.auth().get(environment.service.core.user + '/persons/' + id);
  }

  putPersonProfile(body?: any) {
    return this.http.put(environment.service.wimMaster + '/Persons/', body);
  }

  putResetPassword(body?: any) {
    return this.http.put(environment.service.wimMaster + '/Users/reset/', body);
  }

  putPersonProfileByID(body?: any) {
    return this.http.put(environment.service.wimMaster + '/Persons/person/', body);
  }

  postPersonRegister(body?: ModelPersonMT) {
    return this.http.auth().post(environment.service.core.user + '/persons/', body);
  }

  getPersonList() {
    return this.http.auth().get(environment.service.core.user + '/persons/');
  }

  checkSupervisorKey(body?: any) {
    return this.http.auth().post(environment.service.core.user + '/persons/chksupkey', body);
  }

  changePassword(body?: any) {
    return this.http.auth().put(environment.service.core.user + '/persons/chpw', body);
  }

  resetPassword(body?: any) {
    return this.http.auth().put(environment.service.core.user + '/persons/rspw', { userId: body });
  }

  checkExpirePassword() {
    return this.http.auth().put(environment.service.core.user + '/persons/eppw', {});
  }


}


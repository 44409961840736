import { Injectable, EventEmitter } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel, HttpTransportType } from '@aspnet/signalr';
import { Subject } from 'rxjs';
import { environment } from '@env/environment';
import Swal from 'sweetalert2';
import { TokenHelperService } from './authentication/token-helper.service';
import { StorageService } from './storage.service';
import { IdentityService } from './authentication/identity.service';

@Injectable({
    providedIn: 'root'
})
export class LoggingSignalrService {
    private _hubConnection: HubConnection;
    private loggingSignalrHubUrl = environment.service.core.loggingSignalR;
    private msgSignalrSource = new Subject();
    private msgSignalrState = new EventEmitter<any>();
    private isConnection = true;
    msgReceived$ = this.msgSignalrSource.asObservable();
    msgState$ = this.msgSignalrState.asObservable();
    public isReady = false;


    constructor(
        private tokenHelperService: TokenHelperService,
        private storage: StorageService,
        private identityService: IdentityService
    ) {
        this.identityService.getIsAuthorized().subscribe(res => {
            if (res) {
                this.init();
            }
        });
    }

    public stop() {
        this.msgSignalrSource.unsubscribe();
        this.msgSignalrState.unsubscribe();
        if (this._hubConnection) {
            this._hubConnection.stop().then(() => {
                console.log('LoggingSignalrService Hub disconnected');
                this.isReady = false;
                this.isConnection = false;
            }).catch(() => {
                console.log('LoggingSignalrService Error while disconnection');
            });
        }

    }

    public start() {
        if (this._hubConnection && !this.isConnection) {
            this.stablishConnection();
        } else {
            this.init();
        }
    }

    public init() {
        const isAuthorized = this.storage.retrieve('IsAuthorized');
        if (isAuthorized) {
            this.register();
            this.stablishConnection();
            this.registerHandlers();
        }
    }

    private register() {
        const token = this.tokenHelperService.GetToken();
        if (token !== '') {
            this._hubConnection = new HubConnectionBuilder()
            .withUrl(this.loggingSignalrHubUrl + '/notificationhub', {
                transport: HttpTransportType.WebSockets,
                accessTokenFactory: () => token
            })
            .configureLogging(LogLevel.Information)
            .build();
        }

    }

    private stablishConnection() {
        this._hubConnection.start()
            .then(() => {
                console.log('LoggingSignalrService Hub connection started');
                this.msgSignalrState.next('ready');
            })
            .catch(() => {
                console.log('LoggingSignalrService Error while establishing connection');
            });
    }

    private registerHandlers() {
        this._hubConnection.on('ServiceLogged', (msg) => {
            Swal({
                title: 'Error!',
                text: msg.message,
                type: 'error',
                confirmButtonText: 'Close'
            });
            console.log('ServiceLogged', msg);
            this.msgSignalrSource.next(msg);
        });
    }
}

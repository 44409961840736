import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable(
  { providedIn: 'root' }
)
export class CustomerService {
  error: any;
  constructor(private http: HttpClient) { }
  // tslint:disable-next-line:member-ordering
  getCustomerByID(url: string, body?: any) {
    return this.http.auth().get(environment.service.wms.project + url, body)
      ;
  }

  getEndCustomerAddress(endCustomerId: number) {
    return this.http.auth().get(environment.service.wms.project + '/customers/end-customer/address/' + endCustomerId)
      ;
  }

  getEndCustomerByProjectId(projectId: string) {
    return this.http.auth().post(environment.service.wms.project + '/customers/end-customers/project', { projectid: projectId })
      ;
  }

  getCustomerList() {
    return this.http.auth().get(environment.service.wms.project + '/customers');

  }

  postCreateCustomer(body?: any) {
    return this.http.auth().post(environment.service.wms.project + '/customers', body)
      ;
  }

  // autoCompleteCustomer(key: string) {
  //   return this.http.auth().get(environment.service.wms.project + '/customers');
  // }

  putEditCustomer(id: string, body?: any) {
    return this.http.auth().put(environment.service.wms.project + '/customers/', body)
      ;
  }

  getEndCustomerDropdownSelect(customerId: number) {
    return this.http.auth().get(environment.service.wms.project + '/customers/dropdown-select/' + customerId);
  }

  postEndCustomerByIdsCustomer(body?: any) {
    return this.http.auth().post(environment.service.wms.project + '/customers/end-customer', body);
  }

  getEndcustomerHistoryPDF(id: string, iframeId: string = 'iframe_pdf') {
    return this.http.auth()
      .disableApiResponse()
      .get(environment.service.wms.project + '/customers/historyPdf/' + id, { observe: 'response', responseType: 'arraybuffer' }).subscribe(resp => {
        this.addFileToIframe(resp, iframeId);
      }
      );
  }


  getEndcustomerHistory(id: string) {
    return this.http.auth()
      .get(environment.service.wms.project + '/customers/history/' + id);
  }


  postManualImportEndcustomerToProject(body?: any) {
    return this.http.auth()
      .post(environment.service.wms.project + '/customers/endcustomerimport', body);
  }

  public addFileToIframe(data: any, iframeId: string) {

    // var type = data.headers.get('Content-Type');
    // let name = data.headers.get('content-disposition');
    // var name = name.substring(name.indexOf('=') + 1);
    const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
    const url = window.URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.width = '100%';
    iframe.height = '700px';
    iframe.src = url;
    document.getElementById(iframeId).innerHTML = '';
    document.getElementById(iframeId).appendChild(iframe);
  }

}

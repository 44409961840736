import { Component, OnInit } from '@angular/core';
import { ProjectService } from '@app/shared/services/project.service';
import { CustomerService } from '@app/shared/services/customer.service';
import { WarehouseService } from '@app/shared/services/warehouse.service';
import { IdentityService } from '@app/core/authentication/identity.service';
import { PopupModalComponent } from '@app/shared/components/popup/popup-modal/popup-modal.component';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StorageService } from '@app/core/storage.service';
import { ProjectConfigService } from '@app/shared/services/project-config.service';

@Component({
  selector: 'app-select-project',
  templateUrl: './select-project.component.html',
  styleUrls: ['./select-project.component.scss']
})
export class SelectProjectComponent implements OnInit {

  data: any;
  showData: any;
  project: any;
  customer = [];
  customer2 = [];
  warehouses = [];
  selectedWarehouse: any;
  selectedCustomer: any;
  selectedFilter: any;
  display = false;
  canSave = false;
  cols: any;

  constructor(
    private _projectService: ProjectService,
    private _projectConfigService: ProjectConfigService,
    private _customerService: CustomerService,
    private _warehouseService: WarehouseService,
    private _identityService: IdentityService,
    private messageGrowl: PopupModalComponent,
    private storageService: StorageService,
    private titleService: Title,
    private router: Router
  ) {
    this.cols = [
      { columnName: 'projectName', headingName: 'Project Name' }
    ];
    this.getProjectList();
    this.getCustomerList();
    this.getWarehouseDropdown();

  }

  get pageTitle(): string {
    return this.titleService.getTitle();
  }

  ngOnInit() {
  }

  getProjectList() {
    this.display = false;
    this.project = {};
    this._projectService.getProjectList().subscribe(res => {
      this.data = res['Result'];
      this.showData = this.data;
    }, () => {
    });
  }

  getWarehouseDropdown() {
    this._warehouseService.getWarehouseDropdownList().subscribe(res => {

      res['Result'].forEach(element => {
        this.warehouses.push({ label: element.text, value: element.value });
      });
    });
  }

  getCustomerList() {
    this._customerService.getCustomerList().subscribe(res => {
      this.customer = Object.assign([], res['Result']);
      res['Result'].forEach(element => {
        this.customer2.push({ label: element.customerNameEn, value: element.id });
      });
    });
  }

  filterCustomer() {
    if (this.selectedFilter.id > 0) {
      this.showData = this.data.filter(x => x.customerId === this.selectedFilter.id);
    } else {
      this.showData = this.data;
    }
  }

  onSelect(param: any) {
    this.storageService.store('projectId', param.id);
    this._projectConfigService.updateProjectConfig();
    this.router.navigate(['modules/admin/projectconfig'], { queryParams: { customerid: param.customerId } });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { StorageService } from '@app/core/storage.service';
import { environment } from '@env/environment';
import { TokenHelperService } from '@app/core/authentication/token-helper.service';
import { Logger } from '@app/core/logger.service';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { UserService } from '@app/shared/services/user.service';
import { Router } from '@angular/router';

const log = new Logger('IdentityService');

@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  private _isAuthorized = new BehaviorSubject<boolean>(false);
  public isAuthorized: boolean;
  public userData: any;
  oldPassword = '';
  newPassword1 = '';
  newPassword2 = '';


  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private tokenHelper: TokenHelperService,
    private userService: UserService
  ) {
    this.isAuthorized = this.storage.retrieve('IsAuthorized');
    if (this.isAuthorized) {
      this.setIsAuthorized(this.isAuthorized);
      this.userData = this.storage.retrieve('userData');
    }
  }

  testHttp() {
    this.http.get('https://reqres.in/api/users?delay=2').subscribe(res => {
      setTimeout(() => {

      }, 1800);
    });
  }

  testRabbit() {
    this.http
      .disableApiPrefix()
      .get(environment.service.wms.receiving + '/rabbit').subscribe();
  }

  public getIsAuthorized(): Observable<boolean> {
    return this._isAuthorized.asObservable();
  }

  public getProjectId(): string {
    return this.userData && this.userData.projectId ? this.userData.projectId : null;
  }

  public getProjectName(): string {
    return this.userData.projectName;
  }

  public getUserEmail(): string {
    return this.userData.email;
  }

  public getMenuPermission(): number[] {
    if (this.userData.permission != (null || undefined)) {
      return this.userData.permission.split(',');
    } else {
      return [];
    }
  }

  public isAdmin(): boolean {
    return this.userData.isAdmin.toLowerCase() === 'true';
  }

  public isOperation(): boolean {
    const roles: string[] = (typeof this.userData.role === 'string') ? [this.userData.role] : this.userData.role;
    const rolesName: string[] = _.map(roles, function (role: any) {
      return (role.split('_').length === 1) ? role.split('_')[0].toLowerCase() : role.split('_')[1].toLowerCase();
    });
    return rolesName.some(x => x === 'operation');
  }

  public isAllowPermission(permissionId: number): boolean {
    if (!this.userData.permission || this.userData.permission === (null || undefined)) {
      return true;
    }
    return this.userData.permission.split(',').some(a => a == permissionId);
  }


  private setIsAuthorized(isAuthorized: boolean): void {
    this.isAuthorized = isAuthorized;
    this._isAuthorized.next(isAuthorized);
  }

  public Authorize() {
    const config = environment.identityConfigs;
    // config.queryParams.nonce = 'N' + Math.random() + '' + Date.now();
    // config.queryParams.state = Date.now() + '' + Math.random();

    // this.storage.store('authStateControl', config.queryParams.state);
    // this.storage.store('authNonce', config.queryParams.nonce);

    const url = environment.service.core.identity + config.authorizeEndpoint
      + this.getQueryParams();

    window.location.href = url;
  }

  public SelectCustomer() {
    this.storage.clearBy('projectId');
    const url = environment.service.core.identity + '/account/selectcustomer?returnUrl='
      + encodeURIComponent('/connect/authorize/callback?' + this.getQueryParams());
    window.location.href = url;
  }

  private getQueryParams() {
    const config = environment.identityConfigs;

    config.queryParams.nonce = 'N' + Math.random() + '' + Date.now();
    config.queryParams.state = Date.now() + '' + Math.random();


    this.storage.store('authStateControl', config.queryParams.state);
    this.storage.store('authNonce', config.queryParams.nonce);

    return Object.keys(config.queryParams).map(key => key + '=' + config.queryParams[key]).join('&');
  }

  private splitIdToken(): any {
    const hash = window.location.hash.substr(1);
    const result: any = hash.split('&').reduce(function (_result: any, _item: string) {
      const parts = _item.split('=');
      _result[parts[0]] = parts[1];
      return _result;
    }, {});
    return result;
  }

  public AuthorizedCallback(): Observable<boolean> {
    const authorizedCallbackObservable: Observable<boolean> = new Observable(observer => {
      this.ResetAuthorizationData();
      const result = this.splitIdToken();

      if (result.error) {
        observer.next(false);
        observer.complete();
      }

      if (result.state !== this.storage.retrieve('authStateControl')) {
        log.debug('AuthorizedCallback incorrect state');
        observer.next(false);
        observer.complete();
      }

      const dataIdToken: any = this.getDataFromToken(result.id_token);

      // validate nonce
      if (dataIdToken.nonce !== this.storage.retrieve('authNonce')) {
        log.debug('AuthorizedCallback incorrect nonce');
        observer.next(false);
        observer.complete();
      }

      this.storage.store('authNonce', '');
      this.storage.store('authStateControl', '');

      // log.debug('AuthorizedCallback state and nonce validated, returning access token');

      this.SetAuthorizationData(result.access_token, result.id_token).subscribe(isAuthorizedCallback => {
        observer.next(isAuthorizedCallback);
        observer.complete();
      });

    });
    return authorizedCallbackObservable;
  }

  public ResetAuthorizationData() {
    this.storage.store('authorizationData', '');
    this.storage.store('authorizationDataIdToken', '');
    this.storage.store('IsAuthorized', false);
    this.storage.store('projectConfig', '');
    this.storage.clearBy('projectId');
    this.setIsAuthorized(false);
  }

  private getDataFromToken(token: any) {

    let data = {};
    if (typeof token !== 'undefined') {
      const encoded = token.split('.')[1];
      data = JSON.parse(this.tokenHelper.urlBase64Decode(encoded));
    }

    return data;
  }


  public SetAuthorizationData(access_token: any, id_token: any): Observable<boolean> {
    const setAuthorizationDataObservable: Observable<boolean> = new Observable(observer => {

      if (this.storage.retrieve('authorizationData') !== '') {
        this.storage.store('authorizationData', '');
      }

      this.storage.store('authorizationData', access_token);
      this.storage.store('authorizationDataIdToken', id_token);
      this.storage.store('IsAuthorized', true);
      this.setIsAuthorized(true);

      this.getUserData()
        .subscribe((data: any) => {
          this.userData = data;
          this.storage.store('userData', data);

          observer.next(true);
          observer.complete();
          this.userService.checkExpirePassword().subscribe((resp: any) => {
            if (resp.Result === 'true') {
              this.changePassword();
            }
          });


          // this.getPermission('82e1db58-0a1e-4dee-aa62-5b12afe432d7')
          //   .subscribe((res: any) => {
          //     /*
          //     COMMENT JAY Fix roleId
          //     */
          //     if (data.role === 'Operation' && res.Result) {
          //       data = Object.assign(data, { permission: res.Result });
          //     }
          //     this.storage.store('userData', data);
          //     observer.next(true);
          //     observer.complete();
          //   });
        },
          error => {
            this.HandleError(error);
            observer.next(false);
            observer.complete();
          },
          () => { });
    });
    return setAuthorizationDataObservable;
  }

  public HandleError(status: number) {
    log.debug(status);
    if (status === 403) {
      log.debug('Forbidden');
    } else if (status === 401) {
      log.debug('Unauthorized');
      this.Logoff();
    }
  }

  private getUserData(): Observable<string[]> {
    return this.http.disableApiPrefix().get<string[]>(environment.service.core.identity + '/connect/userinfo', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.GetToken()
      })
    });
  }

  // private getPermission(roleId: string) {
  //     return this.http
  //         .auth()
  //         .get(environment.service.core.user + '/permissions/' + roleId);
  // }

  public GetToken(): any {
    return this.storage.retrieve('authorizationData');
  }

  public Logoff() {
    const id_token_hint = this.storage.retrieve('authorizationDataIdToken');
    if (id_token_hint !== '') {
      const url =
        environment.service.core.identity + '/connect/endsession?' +
        'id_token_hint=' + encodeURI(id_token_hint) + '&' +
        'post_logout_redirect_uri=' + encodeURI(location.origin);

      this.ResetAuthorizationData();
      window.location.href = url;
    }
  }

  // checkAllocatePermisison(body: any) {
  //   return this.http.auth()
  //     .post(environment.service.wms.outbound + '/allocate/checkpermision', body);
  // }

  enableConfirmButton() {

    this.oldPassword = (<HTMLInputElement>document.getElementById('swal-input1')).value;
    this.newPassword1 = (<HTMLInputElement>document.getElementById('swal-input2')).value;
    this.newPassword2 = (<HTMLInputElement>document.getElementById('swal-input3')).value;

    if (this.newPassword1.length < 8 || this.newPassword2.length < 8 || this.oldPassword.length < 1) {
      Swal.disableConfirmButton();
    } else {
      Swal.enableConfirmButton();
    }
  }

  async changePassword() {
    await Swal({
      title: 'Change Password',
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
      focusConfirm: false,
      html:
        '<span>*new password need at least 8 character.</span>' +
        '<input id="swal-input1" class="swal2-input" type="password" placeholder="Enter Current Password" onpaste="return false;">' +
        '<input id="swal-input2" class="swal2-input" type="password" placeholder="Enter New Password" onpaste="return false;">' +
        '<input id="swal-input3" class="swal2-input" type="password" placeholder="Confirm New Password" onpaste="return false;">',
      onOpen: () => {
        Swal.disableConfirmButton();

        document.getElementById('swal-input1')
          .addEventListener('input', (e: any) => {
            if (e) {
              this.enableConfirmButton();
            }
          });

        document.getElementById('swal-input2')
          .addEventListener('input', (e: any) => {
            if (e) {
              this.enableConfirmButton();
            }
          });

        document.getElementById('swal-input3')
          .addEventListener('input', (e: any) => {
            if (e) {
              this.enableConfirmButton();
            }
          });

      }
    }).then(resp => {
      if (resp.value) {
        if (this.newPassword1 === this.newPassword2 && this.newPassword2 !== this.oldPassword) {
          this.userService.changePassword({ newPassword: this.newPassword2, oldPassword: this.oldPassword }).subscribe((res: any) => {

            if (res.Result === 'true') {
              Swal({
                type: 'success',
                text: 'Change Password Complete',
              });
            } else {
              Swal({
                type: 'error',
                text: 'Current Password Not Match',
              });
            }
          });
        } else if (this.newPassword1 !== this.newPassword2) {
          Swal({
            type: 'error',
            text: 'Confirm New Password Not Match',
          });
        } else if (this.newPassword2 === this.oldPassword) {
          Swal({
            type: 'error',
            text: 'New Password Cannot Same as Current Password',
          });
        }

      }
    });
  }

}


import { of as observableOf, merge as observableMerge, Subscription, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Directive, ElementRef, HostListener, OnInit, Input, Renderer2, OnDestroy, AfterViewInit } from '@angular/core';
import { NgControl, FormControl, Validator, AbstractControl, ControlContainer, FormGroupDirective } from '@angular/forms';


@Directive({
  selector: '[appValidation]'
})
export class FormValidateDirective implements AfterViewInit, OnDestroy {
  @Input() formControlName: string;
  control: AbstractControl;
  hasView = false;
  controlValue$: Observable<any>;
  controlSubscription: Subscription;
  hasSubmitted: boolean;

  divError: Element = this.render.createElement('div');

  constructor(private _fg: ControlContainer,
    private _el: ElementRef,
    private render: Renderer2) {
  }

  ngAfterViewInit(): void {
    this.control = this.form.controls[this.formControlName];
    if (this.control !== undefined) {
      const formSubmit$ = (<FormGroupDirective>this._fg).ngSubmit.pipe(map(() => {
        this.hasSubmitted = true;
      }));

      this.divError.setAttribute('class', 'alert alert-danger');
      this.controlValue$ = observableMerge(this.control.statusChanges, observableOf(''), formSubmit$);
      this.controlSubscription = this.controlValue$.subscribe(() => {
        this.checkError();
      });
    }
  }
  private checkError() {
    if (this.control.invalid && (this.control.dirty || this.control.touched)) {
      this.divError.innerHTML = this.getError();
      this.render.appendChild(this._el.nativeElement.parentNode, this.divError);
    } else {
      this.render.removeChild(this._el.nativeElement.parentNode, this.divError);
    }
  }
  private getError() {
    let result = '';
    if (this.control.hasError) {
      if (this.control.errors.required !== undefined) {
        result = this.control.errors.required;
        if (typeof (result) === typeof (true)) {
          return 'Require field';
        }
      } else if (this.control.errors.email !== undefined) {
        result = this.control.errors.email;
      } else if (this.control.errors.maxlength !== undefined) {
        result = 'Max Length is ' + this.control.errors.maxlength.requiredLength;
      }
    }
    return result;
  }
  match(error: string) {
    if (this.control && this.control.errors) {
      if (Object.keys(this.control.errors).indexOf(error) > -1) {
        return true;
      }
    }
    return false;
  }
  @HostListener('blur', ['$event'])
  @HostListener('onBlur', ['$event']) onblur(event: any) {
    this.control.markAsDirty({ onlySelf: true });
    this.checkError();
  }

  get form() { return this._fg.formDirective ? (this._fg.formDirective as FormGroupDirective).form : null; }

  ngOnDestroy() {
    if (this.controlSubscription !== undefined) {
      this.controlSubscription.unsubscribe();
    }
  }

}

export class ReportHelper {
    public static exportToPDFPopup(data: any): void {
        const blob = new Blob([data.body], { type: data.headers.get('Content-Type') });
        const url = URL.createObjectURL(blob);
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = url;
        document.body.appendChild(iframe);
        iframe.contentWindow.print();
      }
}

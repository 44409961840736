import { NbMenuItem } from '@nebular/theme';

export const MASTER_ADMIN_MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Warehouses',
    link: '/modules/admin/master/warehouses'
  },
  {
    title: 'Locations',
    children: [
      {
        title: 'Locations List',
        link: '/modules/admin/master/locations'
      },
      {
        title: 'Location Code Formats',
        link: '/modules/admin/master/locationformat'
      },
      {
        title: 'Location Dimension Formats',
        link: '/modules/admin/master/locationdimension'
      },
      {
        title: 'Location Manage',
        link: '/modules/admin/master/locations-new'
      }
    ]
  },
  {
    title: 'Customers',
    link: '/modules/admin/master/customers'
  },
  {
    title: 'Projects',
    link: '/modules/admin/master/projectslist'
  }
  ,
  {
    title: 'Zone Layout',
    link: '/modules/admin/master/zone',
    children: [
      {
        title: 'Zone Manage',
        link: '/modules/admin/master/zone'
      },
      {
        title: 'Zone 3D',
        link: '/modules/admin/master/zone-3d'
      }
    ]
  }
];

import { Injectable, EventEmitter } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel, HttpTransportType } from '@aspnet/signalr';
import { Subject } from 'rxjs';
import { environment } from '@env/environment';
import Swal from 'sweetalert2';
import { TokenHelperService } from './token-helper.service';
import { StorageService } from '../storage.service';
import { IdentityService } from './identity.service';

@Injectable({
    providedIn: 'root'
})
export class IdentitySignalrService {
    private _hubConnection: HubConnection;
    private identitySignalrHubUrl = environment.service.core.identitySignalR;
    private msgSignalrSource = new Subject();
    private msgSignalrState = new EventEmitter<any>();
    private isConnection = true;
    msgReceived$ = this.msgSignalrSource.asObservable();
    msgState$ = this.msgSignalrState.asObservable();
    public isReady = false;

    constructor(
        private tokenHelperService: TokenHelperService,
        private storage: StorageService,
        private identityService: IdentityService
    ) {
        this.identityService.getIsAuthorized().subscribe(res => {
            if (res) {
                this.init();
            }
        });
    }

    public stop() {
        this.msgSignalrSource.unsubscribe();
        this.msgSignalrState.unsubscribe();
        if (this._hubConnection) {
            this._hubConnection.stop().then(() => {
                console.log('IdentitySignalrService Hub disconnected');
                this.isReady = false;
                this.isConnection = false;
            }).catch(() => {
                console.log('IdentitySignalrService Error while disconnection');
            });
        }
    }

    public start() {
        if (this._hubConnection && !this.isConnection) {
            this.stablishConnection();
        } else {
            this.init();
        }
    }

    public init() {
        const isAuthorized = this.storage.retrieve('IsAuthorized');
        if (isAuthorized) {
            this.register();
            this.stablishConnection();
            this.registerHandlers();
        }
    }

    private register() {
        const token = this.tokenHelperService.GetToken();
        if (token !== '') {
            this._hubConnection = new HubConnectionBuilder()
                .withUrl(this.identitySignalrHubUrl + '/notificationhub', {
                    transport: HttpTransportType.WebSockets,
                    accessTokenFactory: () => this.tokenHelperService.GetToken()
                })
                .configureLogging(LogLevel.Information)
                .build();
        }
    }

    private stablishConnection() {
        this._hubConnection.start()
            .then(() => {
                console.log('IdentitySignalrService Hub connection started');
                this.msgSignalrState.next('ready');
            })
            .catch(() => {
                console.log('IdentitySignalrService Error while establishing connection');
            });
    }

    private registerHandlers() {
        this._hubConnection.on('UserLoggedIn', (msg) => {

            Swal({
                title: 'Error!',
                text: ` tap the link below.
                line://nv/connectedDevices/`,
                type: 'warning',
                confirmButtonText: 'Close'
            });

            Swal({
                title: 'Warning!',
                text: `${msg.status} logged in to your account. If this wasn't you, `,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: `Yes, Logout from ${msg.status}`
            }).then((result) => {
                if (result.value) {

                }
            });
            this.msgSignalrSource.next(msg);
        });
    }
}

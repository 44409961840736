import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  env = environment;
  pass = this.env.encryptConfigs.pwd;
  constructor() {

  }

  decrypt(data: any): any {
    if (this.env.encryptConfigs.enable) {
      const val = CryptoJS.AES.decrypt(data.Result
        , atob(this.pass)).toString(CryptoJS.enc.Utf8);

      data.Result = JSON.parse(val);
    }
    return data;
  }

  forceEncrypt(data: any): string {
    const ret = JSON.stringify(data);
    return CryptoJS.AES.encrypt(ret
      , atob(this.pass)).toString();
  }

  forceDecrypt(data: any): any {
    data = JSON.parse(CryptoJS.AES.decrypt(data
      , atob(this.pass)).toString(CryptoJS.enc.Utf8));
    return data;
  }

  // encrypt(data: any): string {
  //   return CryptoJS.AES.encrypt(data
  //     , atob(this.pass)).toString();
  //   }
  encrypt(data: any): string {
    if (this.env.encryptConfigs.enable) {
      const ret = JSON.stringify(data);
      return CryptoJS.AES.encrypt(ret
        , atob(this.pass)).toString();
    }
    return data;
  }
}

import { Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import { HttpClient, HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ErrorHandlerInterceptor } from '@app/core/http/error-handler.interceptor';
import { CacheInterceptor } from '@app/core/http/cache.interceptor';
import { ApiPrefixInterceptor } from '@app/core/http/api-prefix.interceptor';
import { AuthInterceptor } from '@app/core/http/auth-interceptor';
import { LoaderInterceptor } from '@app/core/http/loader.interceptor';
import { UserActivityLogInterceptor } from '@app/core/http/user-activity-log.interceptor';
import { DisableApiResponseInterceptor } from './disable-api-response-interceptor';
import { EncodeInterceptor } from './encode-interceptor';
import { map } from 'rxjs/operators';

// HttpClient is declared in a re-exported module, so we have to extend the original module to make it work properly
// (see https://github.com/Microsoft/TypeScript/issues/13897)
declare module '@angular/common/http/src/client' {

  // Augment HttpClient with the added configuration methods from HttpService, to allow in-place replacement of
  // HttpClient with HttpService using dependency injection
  export interface HttpClient {

    /**
     * Enables caching for this request.
     * @param {boolean} forceUpdate Forces request to be made and updates cache entry.
     * @return {HttpClient} The new instance.
     */
    cache(forceUpdate?: boolean): HttpClient;

    /**
     * Skips default error handler for this request.
     * @return {HttpClient} The new instance.
     */
    skipErrorHandler(): HttpClient;

    /**
     * Do not use API prefix for this request.
     * @return {HttpClient} The new instance.
     */
    disableApiPrefix(): HttpClient;

    /**
     * Do not use Response Json for this request.
     * @return {HttpClient} The new instance.
     */
    disableApiResponse(): HttpClient;


    /**
    * Do not use Loader for this request.
    * @return {HttpClient} The new instance.
    */
    disableLoader(): HttpClient;

    /**
     * Use Authentication for this request.
     * @return {HttpClient} The new instance.
     */
    auth(): HttpClient;

    /**
    * Do not use Response Json for this request.
    * @return {HttpClient} The new instance.
    */
    encodeApiResponse(): HttpClient;

    /**
     * Use Store UserActivity Log for this request.
     * @return {HttpClient} The new instance.
     */
    activity(type?: string, module?: string, menu?: string, refNo?: string, link?: string): HttpClient;

  }

}

// From @angular/common/http/src/interceptor: allows to chain interceptors
class HttpInterceptorHandler implements HttpHandler {

  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) { }

  handle(request: HttpRequest<any>): Observable<HttpEvent<any>> {

    return this.interceptor.intercept(request, this.next);
  }

}

/**
 * Allows to override default dynamic interceptors that can be disabled with the HttpService extension.
 * Except for very specific needs, you should better configure these interceptors directly in the constructor below
 * for better readability.
 *
 * For static interceptors that should always be enabled (like ApiPrefixInterceptor), use the standard
 * HTTP_INTERCEPTORS token.
 */
export const HTTP_DYNAMIC_INTERCEPTORS = new InjectionToken<HttpInterceptor>('HTTP_DYNAMIC_INTERCEPTORS');

/**
 * Extends HttpClient with per request configuration using dynamic interceptors.
 */
@Injectable()
export class HttpService extends HttpClient {

  constructor(private httpHandler: HttpHandler,
    private injector: Injector,
    @Optional() @Inject(HTTP_DYNAMIC_INTERCEPTORS) private interceptors: HttpInterceptor[] = []) {
    super(httpHandler);

    if (!this.interceptors) {
      // Configure default interceptors that can be disabled here
      this.interceptors = [
        this.injector.get(LoaderInterceptor),
        this.injector.get(ApiPrefixInterceptor),
        this.injector.get(ErrorHandlerInterceptor),
        this.injector.get(EncodeInterceptor)
      ];
    } else {
      this.interceptors.push(this.injector.get(EncodeInterceptor));
    }


  }

  cache(forceUpdate?: boolean): HttpClient {
    const cacheInterceptor = this.injector.get(CacheInterceptor).configure({ update: forceUpdate });
    return this.addInterceptor(cacheInterceptor);
  }

  skipErrorHandler(): HttpClient {
    return this.removeInterceptor(ErrorHandlerInterceptor);
  }

  disableApiPrefix(): HttpClient {
    return this.removeInterceptor(ApiPrefixInterceptor);
  }

  disableApiResponse(): HttpClient {
    const disableApiResponseInterceptor = this.injector.get(DisableApiResponseInterceptor);
    return this.addInterceptor(disableApiResponseInterceptor);
  }

  disableLoader(): HttpClient {
    return this.removeInterceptor(LoaderInterceptor);
  }

  auth(): HttpClient {
    const authInterceptor = this.injector.get(AuthInterceptor);
    return this.addInterceptor(authInterceptor);
  }

  encodeApiResponse(): HttpClient {
    const encodeInterceptor = this.injector.get(EncodeInterceptor);
    return this.addInterceptor(encodeInterceptor);
  }


  activity(type?: string, module?: string, menu?: string, refNo?: string, link?: string): HttpClient {
    const userActivityLogInterceptor = this.injector.get(UserActivityLogInterceptor).configure({
      type: type,
      module: module,
      menu: menu,
      refNo: refNo,
      link: link
    });
    return this.addInterceptor(userActivityLogInterceptor);
  }

  // Override the original method to wire interceptors when triggering the request.
  request(method?: any, url?: any, options?: any): any {
    const handler = this.interceptors.reduceRight(
      (next, interceptor) => new HttpInterceptorHandler(next, interceptor),
      this.httpHandler
    );
    return new HttpClient(handler).request(method, url, options);
  }

  private removeInterceptor(interceptorType: Function): HttpService {
    return new HttpService(
      this.httpHandler,
      this.injector,
      this.interceptors.filter(i => !(i instanceof interceptorType))
    );
  }

  private addInterceptor(interceptor: HttpInterceptor): HttpService {
    return new HttpService(
      this.httpHandler,
      this.injector,
      this.interceptors.concat([interceptor])
    );
  }

}

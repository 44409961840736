import { Component, OnInit, Input } from '@angular/core';
import { AddressService } from '@app/shared/services/address.service';

@Component({
  selector: 'app-form-address',
  templateUrl: './form-address.component.html',
  styleUrls: ['./form-address.component.css']
})
export class FormAddressComponent implements OnInit {
  @Input() result = { zipcode: '', countryCode: '', province: '', city: '', subCity: '', address1: '', address2: '' };
  constructor(
    public addressService: AddressService
  ) { }

  ngOnInit() {
    console.log(this.result)
  }

}

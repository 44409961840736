import { OnInit, Directive, ElementRef, HostListener, OnDestroy, Input, Renderer2 } from '@angular/core';


@Directive({
  selector: '[appEnterToTab]'
})
export class EnterToTabDirective implements OnInit, OnDestroy {

  @Input() enterNo: number;
  maxTrySkip = 7;
  constructor(
    private _el: ElementRef,
    private rd: Renderer2) {
  }

  ngOnInit(): void {
    // const a = this._el.nativeElement;
    // const b = this._el.nativeElement.parentElement;
    // this.rd.setStyle(b,'position','relative')
    // const tp = a.getAttribute('type');
    // const c = this.rd.createElement('button');
    // var t = this.rd.createText("X")
    // this.rd.setAttribute(c,'class','uk-button btn-clear');
    // this.rd.setAttribute(c,'type','reset');
    // this.rd.appendChild(c,t);
    // if(tp == 'number')  {
    //   this.rd.setStyle(c,'right','25px');
    // }
    // this.rd.listen(c,'click',(evt) => {
    //   this.rd.setProperty(a,'value','');
    // });
    // this.rd.appendChild(b,c);
  }

  @HostListener('keydown.enter', ['$event']) onEnter($event: any) {
    if (this.enterNo !== undefined) {
      let curentNo = this.enterNo;
      for (let i = 0; i < this.maxTrySkip; i++) {
        curentNo++;
        const checkautocomp = <HTMLElement>document.querySelector('p-autocomplete[enterNo=\'' + curentNo + '\'] input');
        const checkcalen = <HTMLElement>document.querySelector('p-calendar[enterNo=\'' + curentNo + '\'] input');
        const checkcb = <HTMLElement>document.querySelector('input[type="checkbox"][enterNo=\'' + curentNo + '\']');
        const checkToSerial = <HTMLElement>document.querySelector('.toserial');
        const checkinput = <HTMLElement>document.querySelector('[enterNo=\'' + curentNo + '\']');
      

        if (checkautocomp !== null ) {
          checkautocomp.focus();
          break;
        } else if (checkcalen !== null) {
          checkcalen.focus();
          break;
        } else if (checkcb !== null) {
          checkcb.focus();
          checkcb.click();
          const checkinputForCheckbox = <HTMLElement>document.querySelector('[enterNo=\'' + curentNo + '\']');
          if(checkinputForCheckbox != null) {checkinputForCheckbox.focus();}
          break;
        }
        else if (checkToSerial !== null) {
          document.getElementById('txtSerial1').focus();
          break;
        } else if (checkinput !== null) {
          checkinput.focus();
          break;
        }
      }
    }
  }

  @HostListener('click', ['$event']) onClick($event: any) {
    if($event.target.tagName.toUpperCase() == 'INPUT' 
    || $event.target.tagName.toUpperCase() == 'TEXTAREA'){
      $event.target.select();
    }
   
  }


  ngOnDestroy() {
  }

}

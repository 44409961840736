import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { StorageService } from '@app/core/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { IdentityService } from '@app/core/authentication/identity.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  public langs: string[] = [];
  public langSelected = environment.defaultLanguage;

  constructor(
    private storage: StorageService,
    private translate: TranslateService,
    private identity: IdentityService
  ) { }

  ngOnInit() {
    let lang = this.storage.retrieve('lang');
    lang = lang ? lang : environment.defaultLanguage;
    this.setLangs(lang);
  }

  onChangeLanguage(lang: string) {
    this.setLangs(lang);
  }

  private setLangs(lang: string) {
    const path = this.identity.getProjectId() + '_' + lang;
    this.translate.use(encodeURI(path));
    this.langs = environment.supportedLanguages.filter(_lang => _lang !== lang);
    this.storage.store('lang', lang);
    this.langSelected = lang;
  }

}

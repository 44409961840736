import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '@app/core/logger.service';
import { StorageService } from '@app/core/storage.service';
import { IdentityService } from '@app/core/authentication/identity.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(
    private identityService: IdentityService,
    private router: Router
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    // alert(this.identityService.getProjectId());
    if ((this.router.url === '/' || this.router.url === '/modules') && window.location.hash) {
      return this.identityService.AuthorizedCallback();
    }
    return this.isAuthorized();
  }

  private isAuthorized(): Observable<boolean> | Promise<boolean> | boolean {
    return this.identityService.getIsAuthorized().pipe(
      map((isAuthorized: boolean) => {

        if (isAuthorized) {
          return true;
        }

        this.identityService.Authorize();
        return false;
      })
    );
  }
}
